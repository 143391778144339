import Vue from 'vue';

export default Vue.extend({
    mounted() {
        // @ts-ignore
        let { title } = this.$options
        if (title) {
            title = typeof title === 'function' ? title.call(this) : title
            document.title = title
        }
    }
})
