<template>
  <div>
    <canvas id="planet-chart" ref="canvas" width="300" height="300"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';
// import planetChartData from '../chart-data/tokenomics-chart-data';

export default {
  name: 'PlanetChart',
  props: {
    ChartData: {
      type: Object,
      required: true
    }
  },
  data(){
    return{
      gradient: null,
      Chartdata: this.ChartData,
      data: [40, 40, 10, 5, 5],
    }
  },
  mounted() {
    this.getGradient();
    this.Chartdata.data.datasets[0].borderColor = this.gradient;
    const ctx = document.getElementById('planet-chart');
    this.Chartdata.data.datasets[0].data = this.data;
    new Chart(ctx, this.Chartdata);
  },
  methods:{
    getGradient(){
      this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 400)
      this.gradient.addColorStop(0.5, '#4617FF');
      this.gradient.addColorStop(0.25, '#0096F2')
      this.gradient.addColorStop(0.25, '#5B7BFF');
      this.gradient.addColorStop(0, '#7AE6F4');
    }
  }
}
</script>
