import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import {
    HomePage,
    RoadMap,
    Tokenomics,
    LinkTree,
    WhitePaper,
    Problems,
    Risks,
    Solution,
    Term,
} from "@/views"

const routes: Array<RouteConfig> = [
    {
        path: "",
        name: "homepage",
        component: HomePage,
        meta: {
            title: "home-page"
        }
    },
    {
        path: "/roadmap",
        name: "roadmap",
        component: RoadMap,
        meta: {
            title: "road-map"
        }
    },
    {
        path: "/tokenomics",
        name: "tokenomics",
        component: Tokenomics,
        meta: {
            title: "tokenomics-page"
        }
    },
    {
        path: "/whitepaper",
        name: "whitepaper",
        component: WhitePaper,
        meta: {
            title: "whitepaper-page"
        }
    },
    {
        path: "/linktree",
        name: "LinkTree",
        component: LinkTree,
        meta: {
            title: "linktree-page"
        }
    },
    {
        path: "/problems",
        name: "Problems",
        component: Problems,
        meta: {
            title: "problems-page"
        }
    },
    {
        path: "/risks",
        name: "Risks",
        component: Risks,
        meta: {
            title: "risks-page"
        }
    },
    {
        path: "/solution",
        name: "Solution",
        component: Solution,
        meta: {
            title: "solution-page"
        }
    },
    {
        path: "/term",
        name: "term",
        component: Term,
        meta: {
            title: "term-page"
        }
    },
];

Vue.use(VueRouter);
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    },
    routes
});

export default router;
