<template>
  <div class="readmore-item-wrapper">
    <TsParticles></TsParticles>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="header">
            <img src="../assets/svg/risks-bg.svg" alt="Background Risks" class="bg-header d-none d-lg-block">
            <h1 class="sub-title title">RISKS</h1>
          </div>
          <p class="sub-title-priv">Risks and challenges to the global financial system
            in the fast development of the digital era.</p>
          <div class="content-wrapper mt-90 mb-4">
            <div class="text-wrapper">
              <p class="title">Potential risk of global financial instability</p>
              <p class="description">Every situation has its good and bad sides. While the rapid development of
                technology brings unprecedented opportunities for connectivity and growth, it also comes with risks and
                challenges.<br><br>
                "Debt bomb" is still the biggest risk. The three areas of concern are government debt, corporate debt
                and household debt. Debt burden will be more unpredictable when interest rates rise, especially in
                developing countries.<br><br>
                Increased risk in the insurance and wealth management sectors.
              </p>
            </div>
            <img src="../assets/svg/problem-solving.svg" alt="Problems Solving" class="d-none d-lg-block">
          </div>
          <p class="sub-description">The risk of profit/loss/stoppage of investment funds and businesses is quite common in recent years due
            to the impact of the Covid-19 epidemic, and the war in Ukraine.<br><br>
            Along with that, this leads to fluctuations in commodity and currency prices. The banking system still has
            many potential risks. Cybersecurity and financial crimes are increasingly complex.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TsParticles from '@/components/TsParticles'

export default {
  name: "risks-page",
  components: {
    TsParticles,
  }
}
</script>
