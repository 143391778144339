<template>
  <div class="read-more-wrapper">
    <div class="overlay"></div>
    <div class="card-wrapper">
      <vue-slick-carousel v-bind="settings">
        <div class="card-item" v-for="(item, index) in listCard" :key="index">
          <div class="content">
            <img :src="getSvgUrl(item.svg)" alt="">
            <h5 class="title mt-4">{{ item.title }}</h5>
            <p class="description mt-3">{{ item.description }}</p>
            <router-link class="read-more mt-3" tag="a" :to="{name: item.name}">READ MORE <img
                src="../../assets/svg/right-arrow.svg"
                alt="right-arrow" width="12" height="12">
            </router-link>
          </div>
        </div>
      </vue-slick-carousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      listCard: [
        {
          svg: "problems.svg",
          title: "PROBLEMS",
          description: "The global economy and financial markets have experienced The Most Adverse Shock In A Century. The world of cryptocurrency is a fast moving one.",
          name: "Problems"
        },
        {
          svg: "risks.svg",
          title: "RISKS",
          description: "While the rapid development of technology brings unprecedented opportunities for connectivity and growth, it also comes with risks and challenges.",
          name: "Risks"
        },
        {
          svg: "solution.svg",
          title: "SOLUTION",
          description: "Robo Global Investment (RGI) is a company which has developed an ecosystem of valuable products and practical applications.",
          name: "Solution"
        },

      ],
      settings: {
        arrows: false,
        dots: false,
        swipe: false,
        "infinite": false,
        "slidesToShow": 3,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1300,
            "settings": {
              swipe: true,
              dots: true,
              "slidesToShow": 2,
              "infinite": true,
              "autoplay": true,
              "speed": 500,
              "autoplaySpeed": 5000,
              "cssEase": "linear"
            }
          },
          {
            "breakpoint": 996,
            "settings": {
              dots: true,
              swipe: true,
              "centerMode": true,
              "slidesToShow": 1,
              "centerPadding": "0px",
              "infinite": true,
              "autoplay": true,
              "speed": 500,
              "autoplaySpeed": 5000,
              "cssEase": "linear"
            }
          },
        ]
      },
    }
  },
  methods: {
    getSvgUrl(svg) {
      return require("@/assets/svg/" + svg);
    },
  }
}
</script>
