<template>
  <div class="partners-wrapper">
    <div class="overlay"></div>
    <h5>CORE PARTNERS</h5>
    <div class="partners">
      <vue-slick-carousel v-bind="settingsPartners" class="slide">
        <div v-for="(value, index) in partnersData" :key="index">
          <img :srcset="getImgUrl(value.imgTitle)" height="80px" alt="RoboInu Partner Title">
        </div>
        <template #prevArrow="arrowOption">
          <div class="custom-arrow">
            <img src="../../assets/svg/left-arrow.svg" alt="Left Arrow" width="16" height="21">
            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
          </div>
        </template>
        <template #nextArrow="arrowOption">
          <div class="custom-arrow">
            <img src="../../assets/svg/right-arrow.svg" alt="Right Arrow" width="16" height="21">
            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
          </div>
        </template>
      </vue-slick-carousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  props: ['self'],
  name: 'core-partners-wrapper',
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      partnersData: [
        {
          imgTitle: "unicorn.webp",
        },
        {
          imgTitle: "net-gaming.webp",
        },
        {
          imgTitle: "now-payment.webp",
        },
      ],
      settingsPartners: {
        arrows: false,
        "dots": false,
        "infinite": false,
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "centerPadding": "0px",
        "variableWidth": true,
        "centerMode": true,
        "initialSlide": 2,
        "responsive": [
          {
            "breakpoint": 1200,
            "settings": {
              arrows: true,
              "dots": true,
              "slidesToShow": 3,
              "centerMode": false,
              "initialSlide": 1,
              "infinite": true,
              "autoplay": true,
              "speed": 500,
              "autoplaySpeed": 5000,
              "cssEase": "linear"
            }
          },
          {
            "breakpoint": 992,
            "settings": {
              arrows: true,
              "dots": true,
              "slidesToShow": 2,
              "centerMode": false,
              "initialSlide": 1,
              "infinite": true,
              "autoplay": true,
              "speed": 500,
              "autoplaySpeed": 5000,
              "cssEase": "linear"
            }
          },
          {
            "breakpoint": 576,
            "settings": {
              arrows: true,
              "dots": true,
              "slidesToShow": 1,
              "centerPadding": "0px",
              "infinite": true,
              "autoplay": true,
              "speed": 500,
              "autoplaySpeed": 5000,
              "cssEase": "linear"
            }
          },
        ]
      },

    }
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/img/" + img) + " 2x";
    },
  }
}
</script>

<style>
</style>
