<template>
  <div class="robo-dashboard">
    <div class="info">
      <h5 class="sub-title text-left">ROBODASHBOARD</h5>
      <h4 class="hight-light text-block">
        One-stop for all your ROBO INU needs.
      </h4>
      <div class="tab-menu">
        <a
          class="tab-item"
          :class="{ active: tabName == `dashboard` }"
          @click="selectTab(`dashboard`)"
        >
          Dashboard
        </a>
        <a
          class="tab-item"
          :class="{ active: tabName == `roboDAO` }"
          @click="selectTab(`roboDAO`)"
          >RoboDAO</a
        >
      </div>
      <template v-if="tabName == `dashboard`">
        <p class="text-block">
          RoboDashboard allows you to track your reflections. All you need to do
          is to connect your wallet with our dashboard.
        </p>
        <a href="https://dashboard.roboglobal.info" target="_blank">
          <button class="dashboard">Dashboard</button>
        </a>
      </template>
      <template v-else>
        <p class="text-block">
          Our holders only need to connect their wallets to vote on any
          proposals to help us improve our projects better.
        </p>
        <a href="https://dashboard.roboglobal.info/#vote" target="_blank">
          <button class="dashboard">Vote now</button>
        </a>
      </template>
    </div>
    <template v-if="tabName == `dashboard`">
      <img
        width="100%"
        height="auto"
        srcset="../../assets/img/dashboard.webp 2x"
        alt="Roboinu Dashboard Preview"
        class="d-none d-lg-block"
      />
      <img
        width="100%"
        height="auto"
        srcset="../../assets/img/dashboard.webp 3.5x"
        alt="Roboinu Dashboard Preview"
        class="d-block d-lg-none"
      />
    </template>
    <template v-else>
      <img
        width="100%"
        height="auto"
        srcset="../../assets/img/vote-screen.svg 2x"
        alt="Roboinu Dashboard Preview"
        class="d-none d-lg-block"
      />
      <img
        width="100%"
        height="auto"
        srcset="../../assets/img/vote-screen.svg 3.5x"
        alt="Roboinu Dashboard Preview"
        class="d-block d-lg-none"
      />
    </template>
  </div>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return { tabName: "dashboard" };
  },
  created() {
    this.tabName = "dashboard";
  },
  methods: {
    selectTab(tabName) {
      this.tabName = tabName;
    },
  },
});
</script>
