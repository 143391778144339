<template>
  <div class="tokenomics-wrapper">
    <TsParticles/>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="info">
            <p class="sub-title text-left">ASSET MECHANICS</p>
            <h2>Tokenomics</h2>
            <p class="description">To fuel the growth of the ROBO INU FINANCE ecosystem, a small tax percentage is
              collected for every $RBIF buy and sell transaction.</p>
            <div class="reference">
              <router-link :to="{name: 'whitepaper'}" target="_blank">
                <button class="dashboard">Whitepaper</button>
              </router-link>
              <a href="https://etherscan.io/token/0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b"
                 target="_blank">
                <button class="price-chart">Contract</button>
              </a>
            </div>
          </div>
          <div class="chart">
            <Chart :ChartData="tokenomics"/>
            <div class="label-wrapper">
              <div class="label">
                <div class="color" style="background: #65BBC7"></div>
                <p>Added to Uniswap</p></div>
              <div class="label">
                <div class="color" style="background: #C57110"></div>
                <p>Burned at Launch</p>
              </div>
              <div class="label">
                <div class="color" style="background: #7529D0"></div>
                <p>Marketing & Products</p>
              </div>
              <div class="label">
                <div class="color" style="background: #47A140"></div>
                <p>Fund to join NASA Mission</p>
              </div>
              <div class="label">
                <div class="color" style="background: #A5C21E"></div>
                <p>Donated to Charity</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card-wrapper">
                <div class="card" v-for="(value, index) in card" :key="index">
                  <h1 class="percent">{{ value.percent }}</h1>
                  <p class="title">{{ value.title }}</p>
                  <p class="text" v-if="value.rewards !== ''"><b>{{ value.rewards }}</b> <small>Goes to rewards for
                    holders</small></p>
                  <p class="text" v-if="value.marketing !== ''"><b>{{ value.marketing }}</b> <small>Goes to
                    marketing</small></p>
                  <p class="text" v-if="value.buyback !== ''"><b>{{ value.marketing }}</b> <small>Goes to
                    buyback</small></p>
                </div>
              </div>
            </div>
          </div>
          <div class="info">
            <p class="supply">Total supply: <b>{{ supply }}</b></p>
            <p class="supply">Initial burn at launch: <b>{{ burn }}</b></p>
            <small class="note">*** Each wallet can only purchase maximum 0.5% of the<br> supply each time and no wallet
              can hold more than 2% of<br> the supply</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart";
import TsParticles from '@/components/TsParticles'
import tokenomics from '@/chart-data/chart-data';

export default {
  title: 'Robo Global Investment - Tokenomics',
  name: "tokenomics-page",
  components: {
    Chart,
    TsParticles
  },
  data() {
    return {
      tokenomics: tokenomics,
      card: [
        {
          title: "Buying Tax",
          percent: "0%",
          rewards: "",
          marketing: "",
        },
        {
          title: "Selling Tax",
          percent: "5%",
          rewards: "1%",
          marketing: "2%",
          buyback: "2%"
        },
      ],
      supply: "100,000,000,000,000,000",
      burn: "40%",
    }
  },
}
</script>
