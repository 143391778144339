<template>
  <div class="readmore-item-wrapper">
    <TsParticles></TsParticles>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="header">
            <img src="../assets/svg/problems-bg.svg" alt="Background Problems" class="bg-header d-none d-lg-block">
            <h1 class="sub-title title">PROBLEMS</h1>
          </div>
          <div class="content-wrapper mt-90">
            <div class="text-wrapper">
              <p class="title">Economic Issues</p>
              <p class="description">The global economy and financial markets have experienced "the most adverse shock
                in a century" due to the impact of the Covid-19 pandemic, the war in Ukraine, and the raising of
                interest rates.<br><br>
                However, they are also transforming strongly in the digital era. The world of cryptocurrency is a fast
                moving one. Accordingly, the global financial system in the next few years will witness many key trends,
                with many opportunities for those who are prepared.<br><br>
                Under the impact of digital transformation, and the catalysts of the epidemic and war, the process of
                closely connecting countries around the world is progressing more rapidly than ever before. The trend of
                consuming financial services is heading in a more positive direction, and it is now possible to observe
                the outlook of the global financial system in the coming period.<br><br>
              </p>
            </div>
            <img src="../assets/svg/problem-solving.svg" alt="Problems Solving" class="d-none d-lg-block">
          </div>
          <div class="content-wrapper">
            <img src="../assets/svg/planning-business.svg" alt="Planning Business" class="d-none d-lg-block">
            <div class="text-wrapper">
              <p class="title">Strong digital transformation trends</p>
              <p class="description">The development of the Industrial Revolution 4.0 along with the trend of digital
                transformation has changed all aspects of the financial services sector. Modern and breakthrough
                technologies such as cloud computing, big data, artificial intelligence, blockchain, the Internet of
                Things, process automation by robots, biometrics, and virtual reality technology are increasingly widely
                applied, contributing to diversifying activities and distribution channels, modernizing financial
                products
                and services, increasing understanding of customer needs and behaviour, as well as productivity and
                operational quality of the financial system.<br><br>
                Digital transformation is also appearing in financial fields such as insurance, real estate and
                financial
                education, along with the strong development of global financial technology hubs.
              </p></div>
          </div>
          <div class="content-wrapper">
            <div class="text-wrapper">
              <p class="title">Cryptocurrency development trends </p>
              <p class="description">Cryptocurrencies are financial products based on blockchain technology, so the
                trend of existence and development of cryptocurrencies is inevitable by the resonance of fundamental and
                key driving factors such as the strong development and strength of technology, development of modern
                payments and the digital economy, and the risks related to cash and traditional currencies.<br><br>

                The ability to decentralize and not be governed by anyone is the driving force behind the development of
                cryptocurrencies.
              </p>
              <p class="title mt-5">The trend of connecting countries with each other is growing.</p>
              <p class="description">Thanks to the internet and developed technology, countries around the world are
                connecting with each other more than ever. This has led to a strong boost in the development of global
                finance, with each country being a piece of the puzzle. Instead of having to wait weeks or longer to
                fulfill cross-border orders, we are now doing it with just a few clicks.
              </p>
            </div>
            <img src="../assets/svg/goal-business.svg" alt="Goal Business" class="d-none d-lg-block">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TsParticles from '@/components/TsParticles'

export default {
  name: "problems-page",
  components: {
    TsParticles,
  }
}
</script>
