<template>
  <div class="introduce">
    <vue-typed-js :strings="['FinTech reinvented by its <span>community.</span>']"
                  :showCursor="false"
                  :typeSpeed="50"
                  :contentType="'html'">
      <h1 class="typing"></h1>
    </vue-typed-js>
    <p>Inspired by NASA’s ambitious plan to launch Robo-dogs to Mars, ROBO INU FINANCE aims to build an
      efficient circular ecosystem that brings together the best fintech resources. Join us and be a part of
      something revolutionary.&nbsp;&nbsp;&nbsp;&nbsp;<span @click="scrollMeTo('focus-how-to-buy')">GET STARTED <img
          src="../../assets/svg/get-started.svg" alt="How to Buy"></span>
    </p>

    <div class="reference">
      <button @click="scrollMeTo('dashboard')" class="dashboard">Dashboard</button>
      <div class="d-flex align-items-center" style="gap: 0 16px;">
        <a href="https://www.dextools.io/app/ether/pair-explorer/0xb49d78203e9dca6e1dd758ba8ddadbf8fd29c263"
           target="_blank">
          <button class="price-chart">Price chart</button>
        </a>
        <a href="https://coinmarketcap.com/currencies/robo-inu-finance/" target="_blank"><img
            src="../../assets/svg/coin-market.svg" alt="CoinMarketCap RoboInu"></a>
        <a href="https://www.coingecko.com/en/coins/robo-inu-finance" target="_blank"><img
            src="../../assets/svg/coin-gecko.svg" alt="CoinGecko Roboinu"></a>
      </div>
    </div>
    <h4 class="mt-90 mb-0">AS FEATURED ON</h4>
    <div class="as-featured-on mt-16">
      <vue-slick-carousel v-bind="settingsFeatured">
        <div>
          <a href="https://finance.yahoo.com/news/robo-global-investment-announces-launch-183000922.html" target="_blank">
            <img srcset="../../assets/img/yahoo_finance.png 1.5x" alt="Roboinu Yahoo Finance Partner">
          </a>
        </div>
        <div>
          <a href="https://www.benzinga.com/pressreleases/23/03/g31484485/robo-inu-finance-to-solidify-its-place-in-memecoin-history-with-new-fintech-products"
             target="_blank">
            <img srcset="../../assets/img/benzinga.png 1.5x" alt="Roboinu Benzinga Partner">
          </a>
        </div>
        <div>
          <a href="https://www.bloomberg.com/press-releases/2023-03-23/robo-inu-finance-to-solidify-its-place-in-memecoin-history-with-new-fintech-products"
             target="_blank">
            <img srcset="../../assets/img/bloomberg.png 1.5x" alt="Roboinu Bloomberg Partner">
          </a>
        </div>
        <div>
          <a href="https://blockchainwire.io/press-release/robo-inu-finance-to-solidify-its-place-in-memecoin-history-with-new-fintech-products"
             target="_blank">
            <img srcset="../../assets/img/blockchainwire.png 1.5x" alt="Roboinu BlockChainWire Partner">
          </a>
        </div>
        <div>
          <a href="https://www.bitcoininsider.org/article/206683/robo-inu-finance-set-next-bull-market-what-it-and-why-you-should-invest-now"
             target="_blank">
            <img srcset="../../assets/img/bitcoininsider.png 1.5x" alt="Roboinu BitcoinInsider Partner">
          </a>
        </div>
        <div>
          <a href="https://www.theamericanreporter.com/pushing-out-of-the-meme-space-how-rbif-is-entering-market-leadership-with-its-suite-of-pioneering-products/"
             target="_blank">
            <img srcset="../../assets/img/theamericanreporter.png 1.5x" alt="Roboinu The American Reporter Partner">
          </a>
        </div>
        <div>
          <a href="https://skynet.certik.com/projects/robo-inu"
             target="_blank">
            <img srcset="../../assets/img/certik.png" alt="Roboinu Certik Partner">
          </a>
        </div>
      </vue-slick-carousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import {VueTypedJs} from 'vue-typed-js'

export default {
  props: ['self'],
  name: 'introduce-wrapper',
  components: {
    VueSlickCarousel,
    VueTypedJs,
  },
  data() {
    return {
      settingsFeatured: {
        arrows: false,
        swipe: false,
        dots: false,
        slidesPerRow: 3,
        rows: 3,
        "variableWidth": true,
        "initialSlide": 0,
        "speed": 500,
        "autoplaySpeed": 5000,
        "responsive": [
          {
            "breakpoint": 992,
            "settings": {
              swipe: true,
              dots: true,
              slidesPerRow: 1,
              rows: 1,
              "infinite": true,
              "slidesToShow": 3,
              "slidesToScroll": 1,
              "touchThreshold": 5,
            }
          },
          {
            "breakpoint": 767,
            "settings": {
              swipe: true,
              dots: true,
              slidesPerRow: 1,
              rows: 1,
              "infinite": true,
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "touchThreshold": 5,
              "swipeToSlide": true,
              "autoplay": true,
            }
          },
        ]
      },
    }
  },
  methods: {
    scrollMeTo(refName) {
      let container = this.self.$refs[refName];
      if (container) {
        container.scrollIntoView({behavior: 'smooth'})
      }
    },
  }
}
</script>
