<template>
  <div class="roadmap-wrapper">
    <TsParticles/>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="sub-title text-left">ROADMAP</p>
          <h2>Buckle up for lift-off.</h2>
          <p class="description">With NASA’s inspiration and the need for fintech development and growth, ROBO INU
            FINANCE was born. We dream to support the cause of NASA as well as to build an open ecosystem for everyone
            regardless of your age or your race to gain your financial freedom. We aim to create financial tools for
            individuals and companies to control their finances, leveraging on AI (Artificial Intelligence) to enhance
            people’s lives in the fintech industry.<br><br>
            This ROBO INU roadmap is a summary of ROBO INU’s development, which has been organized into four phases.</p>

          <div class="card-wrapper mt-90">
            <vue-slick-carousel v-bind="settings">
              <div class="card-content" :key="1" @click="spaceTraining = true" @mouseleave="spaceTraining = false">
                <p class="milestone-number">01</p>
                <p>Space Training</p>
                <div class="mile-stone" v-if="spaceTraining">
                  <img src="../assets/svg/done.svg" alt="" class="done">
                  <p class="text-left text-success ml-5">Launch $RBIF token on ERC-20</p>

                  <img src="../assets/svg/done.svg" alt="" class="done">
                  <p class="text-left text-success ml-5">Contract audit</p>

                  <img src="../assets/svg/done.svg" alt="" class="done">
                  <p class="text-left text-success ml-5">List on CoinGecko</p>

                  <img src="../assets/svg/done.svg" alt="" class="done">
                  <p class="text-left text-success ml-5">List on CoinMarketCap</p>

                  <img src="../assets/svg/done.svg" alt="" class="done">
                  <p class="text-left text-success ml-5">5,000 holders</p>

                  <img src="../assets/svg/done.svg" alt="" class="done">
                  <p class="text-left text-success ml-5">Release Whitepaper</p>

                </div>
                <div class="line green-line"></div>
              </div>
              <div class="card-content" :key="2" @click="firstMission = true" @mouseleave="firstMission = false">
                <p class="milestone-number">02</p>
                <p>First Mission</p>
                <div class=" mile-stone" v-if="firstMission">
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">10,000 holders</p>
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">Aggressive marketing roll out</p>
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">NASA and charity donations</p>
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">Solid partnership with utility focus</p>

                </div>
                <div class="line yellow-line"></div>
              </div>
              <div class="card-content" :key="3" @click="relauch = true" @mouseleave="relauch = false">
                <p class="milestone-number">03</p>
                <p>Relaunch</p>
                <div class="mile-stone" v-if="relauch">
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">Launch RGI Wallet</p>
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">CEX Listing</p>
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">50,000 holders</p>
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">Real world events</p>
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">Major partnership</p>
                </div>
                <div class="line red-line"></div>
              </div>
              <div class="card-content" :key="4" @click="refuelling = true" @mouseleave="refuelling = false">
                <p class="milestone-number">04</p>
                <p>Refuelling</p>
                <div class="mile-stone" v-if="refuelling">
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">Launch RoboLaunchpad</p>
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">Massive donation</p>
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5"> 100,000 holders</p>
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">Open RoboNFT Marketplace</p>
                  <img src="../assets/svg/pending.svg" alt="" class="pending">
                  <p class="text-left text-warning ml-5">Establish RoboVentures</p>
                </div>
                <div class="line red-line"></div>
              </div>
            </vue-slick-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import TsParticles from '@/components/TsParticles'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  title: 'Robo Global Investment - RoadMap',
  components: {
    VueSlickCarousel,
    TsParticles
  },
  data() {
    return {
      spaceTraining: false,
      firstMission: false,
      relauch: false,
      refuelling: false,
      settings: {
        arrows: false,
        swipe: true,
        dots: true,
        "infinite": false,
        "slidesToShow": 3,
        "initialSlide": 0,
        "slidesToScroll": 1,
        "centerMode": false,
        "responsive": [
          {
            "breakpoint": 992,
            "settings": {
              "slidesToShow": 2,
            }
          },
          {
            "breakpoint": 576,
            "settings": {
              "slidesToShow": 1,
              "centerPadding": "0px",
            }
          },
        ]
      },
    }
  },
}
</script>

<style>
.green-line {
  border: 2px solid #28a745;
}
.red-line {
  border: 2px solid rgba(254, 38, 38, 0.44);
}

.yellow-line {
  border: 2px solid rgba(254, 206, 38, 0.44);
}
</style>
