export const tokenomics = {
    type: "doughnut",
    data: {
        labels: ["Added to Uniswap", "Burned at Launch", "Marketing & Products", "Fund to join NASA Mission", "Donated to Charity"],
        datasets: [
            {
                // label: "Number of Moons",
                data: [],
                backgroundColor: [
                    '#65BBC7',
                    '#C57110',
                    '#7529D0',
                    '#47A140',
                    '#A5C21E',
                ],
                borderColor: '#4617FF',
                borderWidth: 3
            },
        ]
    },
    options: {
        responsive: false,
        legend: {
            display: false,
        },
        cutoutPercentage: 30
    }
};

export default tokenomics;
