<template>
  <div class="homepage-wrapper">
    <TsParticles/>
<!--    <ButtonExchange class="d-none d-lg-block" @click.native="showExchange = !showExchange"></ButtonExchange>-->
    <div class="popup-exchange" v-if="showExchange">
      <iframe id="iframe-widget" style="height: 205px; width: 100%; border: none;"
              src="https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=false&amp;amount=1&amp;backgroundColor=FFFFFF&amp;darkMode=false&amp;from=eth&amp;horizontal=true&amp;lang=en-US&amp;link_id=5212283667fe15&amp;locales=true&amp;logo=false&amp;primaryColor=28acf6&amp;to=rbif&amp;toTheMoon=true"></iframe>
    </div>
    <div class="container">
      <!--      introduce-->
      <div class="row">
        <div class="col-12">
          <Introduce :self="this"></Introduce>
        </div>
      </div>
      <div class="rbif-rocket-home">
        <div class="bg"></div>
        <img src="../assets/svg/ROBOINURocket1.svg" alt="RoboInu Rocket">
      </div>
      <!--      Read More-->
      <div class="row">
        <div class="col-12">
          <ReadMore></ReadMore>
        </div>
      </div>
      <!--      who we are-->
      <div class="row">
        <div class="col-12">
          <WhoWeAre></WhoWeAre>
        </div>
      </div>
      <!--      why robo-->
      <div class="row">
        <div class="col-12">
          <WhyRobo></WhyRobo>
        </div>
      </div>
      <!--      our community-->
      <div class="row">
        <div class="col-12">
          <OurCommunity></OurCommunity>
        </div>
      </div>
      <!--      how to buy-->
<!--      <div class="row justify-content-end">-->
<!--        <div class="col-12" ref="focus-how-to-buy" id="focus-how-to-buy">-->
<!--          <HowToBuy></HowToBuy>-->
<!--        </div>-->
<!--      </div>-->
      <!--      robo dashboard-->
      <div class="row">
        <div class="col-12" ref="dashboard" id="dashboard">
          <RoboDashBoard></RoboDashBoard>
        </div>
      </div>
      <!--      partners-->
      <div class="row">
        <div class="col-12">
          <CorePartners></CorePartners>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ButtonExchange from '@/components/ButtonExchange'
import TsParticles from '@/components/TsParticles'
import isMobile from '@/helper/platform'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {
  Introduce,
  CorePartners,
  WhyRobo,
  OurCommunity,
  WhoWeAre,
  RoboDashBoard,
  ReadMore,
} from '@/views/homepage/index.ts'

export default {
  title: 'Robo Global Investment - Home',
  components: {
    // ButtonExchange,
    TsParticles,
    Introduce,
    CorePartners,
    WhyRobo,
    OurCommunity,
    WhoWeAre,
    RoboDashBoard,
    ReadMore,
  },
  data() {
    return {
      showExchange: false,

    }
  },
  methods: {
    scrollMeTo(refName) {
      let container = this.$refs[refName];
      if (container) {
        container.scrollIntoView({behavior: 'smooth'})
      }
    },
  },
  computed: {
    isMobile() {
      return isMobile();
    }
  }
}
</script>
