<template>
  <div id="app">
    <Header v-if="!hideHeaderFooter"></Header>
    <NavBarModal v-if="!hideHeaderFooter"></NavBarModal>
    <router-view></router-view>
    <Footer v-if="!hideHeaderFooter"></Footer>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue'
import NavBarModal from '@/components/modal/NavbarModal.vue';

export default Vue.extend({
  name: 'App',
  components: {
    Header,
    Footer,
    NavBarModal,
  },
  data() {
    return {
      hideHeaderFooter: false,
    }
  },
  methods: {
    checkHideHeaderFooter() {
      if(this.$route.name == "LinkTree") {
        this.hideHeaderFooter = true;
      }
    }
  },
  created() {
    this.checkHideHeaderFooter();
  }
});
</script>

<style>
#app{
  width: 100vw;
}
</style>
<style lang="scss">
@import "./assets/scss/styles.scss";
</style>
