<template>
  <div class="footer-wrapper">
    <div class="container footer-content">
      <div class="row" v-if="!this.isMobile">
        <div class="col-3 mt-60">
          <div class="details">
            <img src="../assets/svg/footer-logo-tm.svg" alt="RoboInu Logo" width="180" height="135">
            <p class="copy-right mt-4">Copyright © 2021-2024 Robo Global Investment. All Rights Reserved</p>
          </div>
        </div>
        <div class="col-5 info-wrapper mt-60">
          <div class="info">
            <h3>Community</h3>
            <a href="https://t.me/robo_inu" target="_blank">
              <p>Telegram</p>
            </a>
            <a href="https://twitter.com/RGI_info" target="_blank">
              <p>Twitter</p>
            </a>
            <a href="https://roboglobal.medium.com/" target="_blank">
              <p>Medium</p>
            </a>
            <a href="https://www.facebook.com/groups/318598893018719" target="_blank">
              <p>Facebook</p>
            </a>
            <a href="https://www.reddit.com/r/roboinu" target="_blank">
              <p>Reddit</p>
            </a>
            <div class="mt-4">
              <h3>Support</h3>
              <a href="mailto:support@roboglobal.info">
                <p>support@roboglobal.info</p>
              </a>
            </div>
          </div>
          <div class="info">
            <h3>Resources</h3>
            <a href="https://www.dextools.io/app/ether/pair-explorer/0xb49d78203e9dca6e1dd758ba8ddadbf8fd29c263"
              target="_blank">
              <p>Price chart</p>
            </a>
            <router-link :to="{ name: 'whitepaper' }">
              <p>Whitepaper</p>
            </router-link>
            <a href="https://etherscan.io/token/0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b">
              <p>Contract</p>
            </a>
            <a
              href="https://github.com/roboinu/roboinu-contracts/blob/main/audit/ROBO%20INU%20Full%20Smart%20Contract%20Security%20Audit.pdf">
              <p>Security Audit</p>
            </a>
            <router-link :to="{ name: 'term' }" tag="a">
              <p>Privacy & Terms</p>
            </router-link>
            <div class="mt-4">
              <h3>Follow us</h3>
              <div class="community-icon">
                <a href="https://www.instagram.com/roboglobal_info" target="_blank"><img
                    src="../assets/svg/instagram.svg" alt="RGI Instagram" width="32" height="32"></a>
                <a href="https://twitter.com/RGI_info" target="_blank"><img src="../assets/svg/twitter.svg"
                    alt="RGI Twitter" width="32" height="32"></a>
                <a href="https://www.youtube.com/c/RoboInuFinance" target="_blank"><img src="../assets/svg/youtube.svg"
                    alt="RGI Reddit" width="32" height="32"></a>
                <a href="https://www.facebook.com/groups/318598893018719" target="_blank"><img
                    src="../assets/svg/facebook.svg" alt="RGI Facebook" width="32" height="32"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 mt-60">
          <h3>Download Now</h3>
          <a href="https://play.google.com/store/apps/details?id=com.roboglobal.wallet">
            <img src="../assets/svg/download_android_store.svg" class="img-fluid " alt="Android Store Download">
          </a>
          <a href="https://apps.apple.com/vn/app/rgi-wallet/id1659875563">
            <img src="../assets/svg/download_ios_store.svg" class="img-fluid mt-n5 mb-n5" alt="Apple Store Download">
          </a>
          <a href="https://chromewebstore.google.com/detail/rgi-wallet/jhckpnnnpdipkakahjjhlpnieglnjcic">
            <img src="../assets/img/chrome_store_download.png" width="270" class="img-fluid" alt="Chrome Store Download">
          </a>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12 info-wrapper mt-60">
          <div class="info">
            <h3>Community</h3>
            <a href="https://t.me/robo_inu" target="_blank">
              <p>Telegram</p>
            </a>
            <a href="https://twitter.com/RGI_info" target="_blank">
              <p>Twitter</p>
            </a>
            <a href="https://roboglobal.medium.com/" target="_blank">
              <p>Medium</p>
            </a>
            <a href="https://www.facebook.com/groups/318598893018719" target="_blank">
              <p>Facebook</p>
            </a>
            <a href="https://www.reddit.com/r/roboinu" target="_blank">
              <p>Reddit</p>
            </a>
            <div class="mt-4">
              <h3>Support</h3>
              <a href="mailto:support@roboglobal.info">
                <p>support@roboglobal.info</p>
              </a>
              <div class="community-icon">
                <a href="https://www.instagram.com/roboglobal_info" target="_blank"><img
                    src="../assets/svg/instagram.svg" alt="RGI Instagram" width="32" height="32"></a>
                <a href="https://twitter.com/RGI_info" target="_blank"><img src="../assets/svg/twitter.svg"
                    alt="RGI Twitter" width="32" height="32"></a>
                <a href="https://www.youtube.com/c/RoboInuFinance" target="_blank"><img src="../assets/svg/youtube.svg"
                    alt="RGI Reddit" width="32" height="32"></a>
                <a href="https://www.facebook.com/groups/318598893018719" target="_blank"><img
                    src="../assets/svg/facebook.svg" alt="RGI Facebook" width="32" height="32"></a>
              </div>
            </div>
          </div>
          <div class="info">
            <h3>Resources</h3>
            <a href="https://www.dextools.io/app/ether/pair-explorer/0xb49d78203e9dca6e1dd758ba8ddadbf8fd29c263"
              target="_blank">
              <p>Price chart</p>
            </a>
            <router-link :to="{ name: 'whitepaper' }">
              <p>Whitepaper</p>
            </router-link>
            <a href="https://etherscan.io/token/0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b">
              <p>Contract</p>
            </a>
            <a
              href="https://github.com/roboinu/roboinu-contracts/blob/main/audit/ROBO%20INU%20Full%20Smart%20Contract%20Security%20Audit.pdf">
              <p>Security Audit</p>
            </a>
            <router-link :to="{ name: 'term' }" tag="a">
              <p>Privacy & Terms</p>
            </router-link>
            <div class="mt-4">
              <img src="../assets/svg/footer-logo-tm.svg" alt="RoboInu Logo" width="135" height="110">
            </div>
          </div>
        </div>
        <div class="col-12 mt-60">
          <div class="details">
            <p class="copy-right">Copyright © 2021-2024 Robo Global Investment. All Rights
              Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import isMobile from '@/helper/platform';

export default {
  name: "footer-bar",
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
    isMobile() {
      return isMobile();
    }
  }
}
</script>
