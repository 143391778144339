import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import title from '@/mixins/title'
import {store} from "@/store";
import VueLazyload from 'vue-lazyload'


declare module "particles.vue";
import VueParticles from "particles.vue";

Vue.use(VueLazyload)
Vue.use(VueParticles);


Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue();

Vue.mixin(title);

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
