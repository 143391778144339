import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/store/state'

Vue.use(Vuex)

export const store = new Vuex.Store({
    strict: true,
    state,
    getters: {},
    mutations: {
        setShowNavBar(state, payload){
            state.showNavBar = payload;
        }
    },
    actions: {}
})
