<template v-if="isLoadingFinished">
    <div class="linktree-wrapper whitepaper-wrapper">
        <TsParticles></TsParticles>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="header">
                        <h1 class="sub-title title">WHITEPAPER</h1>
                    </div>
                    <div class="loading" v-if="!isLoadingFinished">
                        <img src="@/assets/svg/loading.svg" alt="loading">
                    </div>
                    <vue-pdf-embed ref="pdfRef"
                                   :source="pdfSource"
                                   :page="page"
                                   @password-requested="handlePasswordRequest"
                                   @rendered="handleDocumentRender"
                                   @loaded="isLoadingFinished = true"
                    />
                    <div class="pagination-wrapper" v-if="isLoadingFinished">
                        <button class="btn btn-custom previous round" :disabled="page <= 1" @click="page--">❮</button>
                        <span class="pagination-custom">{{ page }} / {{ pageCount }}</span>
                        <button class="btn btn-custom next round" :disabled="page >= pageCount" @click="page++">❯
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TsParticles from '@/components/TsParticles'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
    title: 'Robo Global Investment - WhitePaper',
    components: {
        VuePdfEmbed,
        TsParticles,
    },
    data() {
        return {
            isLoadingFinished: false,
            page: 1,
            pageCount: 1,
            pdfSource: 'https://raw.githubusercontent.com/roboinu/roboinu-contracts/main/documents/RGI-WhitePaper.pdf',
        }
    },
    methods: {
        handleDocumentRender() {
            this.pageCount = this.$refs.pdfRef.pageCount
        },
    }
}
</script>
