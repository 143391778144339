<template>
  <div class="how-to-buy-block">
    <img srcset="../../assets/img/coin-bg.webp 2x" alt="Roboinu Background" class="bg-coin d-none d-lg-block">
    <div class="how-to-buy">
      <div class="title">
        <h2 class="text">How to buy $RBIF?</h2>
        <img srcset="../../assets/img/coin-title.webp 2x" alt="Roboinu Coin Title" class="d-none d-lg-block">
        <img srcset="../../assets/img/coin-title.webp 3x" alt="Roboinu Coin Title" class="d-block d-lg-none">
      </div>
      <div class="card-content" v-for="(value, index) in stepHowtoBuyRBIF" :key="index">
        <div class="card-title" @click="opened = index">
          <div class="number rounded-circle">0{{ index + 1 }}</div>
          <h4>{{ value.title }}</h4>
        </div>
        <div class="description" v-if="check(index)" v-html="value.description">
        </div>
      </div>
      <div class="card-footer">
        <a href="https://dex.roboglobal.info/swap?outputCurrency=0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b"
           target="_blank">
          <button class="btn">Get $RBIF</button>
        </a>
        <router-link tag="a" :to="{name: 'tokenomics'}" class="mt-2 text-decoration-none">
          <small>TOKENOMICS <img src="../../assets/svg/get-started.svg" alt="Roboinu Get Started"></small>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stepHowtoBuyRBIF: [
        {
          title: "Download MetaMask or Trust Wallet",
          description: `You can download the <a href="https://metamask.io/download/" target="_blank">MetaMask app</a> or <a href="https://trustwallet.com/download" target="_blank">Trust Wallet app</a>. You can also use MetaMask on desktop by downloading the Google Chrome <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank">extension</a>.<br><br>
                        Additionally, use any other ERC-20 compatible wallet for which you own the private keys.<br><br>
                        Do not send to an exchange wallet unless $RBIF is officially listed on that exchange and has a deposit address.`
        },
        {
          title: "Connect wallet to ERC-20 Network",
          description: `Cryptocurrencies and tokens are often on different networks known as blockchains.<br><br>
                        Since the <b>$RBIF</b> token is on <b>ERC-20</b>, you will need to connect your wallet to <b>ERC-20</b> if it isn’t already.<br><br>
                        This process is pretty quick and easy, anyone can do it following some simple instructions.`
        },
        {
          title: "Get ETH",
          description: `Before you can actually buy any token on the ERC-20, you must first send some ETH to your wallet. You can send ETH from another wallet including exchange wallets like Binance and Coinbase.<br><br>
                        <a href="https://www.binance.com/en" target="_blank">Binance website</a> – <a href="https://www.binance.com/en/download" target="_blank">Binance App</a><br><br>
                        <a href="https://www.coinbase.com/fr/" target="_blank">Coinbase website</a> – <a href="https://www.coinbase.com/wallet" target="_blank">Coinbase App</a><br><br>
                        Every transaction on <b>ERC-20</b> uses <b>ETH</b> to pay a very small gas fee. You will see the gas fee details when you confirm the transaction in your wallet.<br><br>
                        You do not need to pay gas fees when buying a token from a centralized exchange like Binance, but are required to when buying from a Decentralized exchange (DEX) like <b>RoboEx</b>. Remember that you can actually swap any <b>ERC-20</b> token for another, so you can swap <b>$RBIF</b> into any other <b>ERC-20</b> token on <a>RoboEx</a> at any time.`
        },
        {
          title: "Buy $RBIF",
          description: `Now you have <b>ETH</b> in your wallet, and you can buy <b>$RBIF</b>.<br><br>
                        Before you try to buy, you must click the settings button and change the slippage to 4-5 %. This is important because <b>$RBIF</b> comes with a 5% sales tax (see our Tokenomics section). The transaction may not work without this. For more info on RoboEx, visit this trading guide.<br><br>
                        Here is a little more info on how to use <b>RoboEx</b>.<br>
                        Once you have bought <b>$RBIF</b>, you will need to manually add the token to your wallet to reflect your balance.<br><br>
                        <a href="https://community.trustwallet.com/t/how-to-add-a-custom-token/213" target="_blank">Add token to Trust Wallet guide</a><br>
                        <a href="https://metamask.zendesk.com/hc/en-us/articles/360015489031-How-to-view-see-your-tokens-custom-tokens-in-MetaMask" target="_blank">Add token to MetaMask guide</a><br><br>
                        Congratulations, now you have $RBIF in your wallet! Join us as we HODL and ascend on our space mission! Check out our <a href="/roadmap">Roadmap</a>, and if you need help or have questions, chat with us in our <a href="https://t.me/robo_inu" target="_blank">Telegram group</a>.`
        },
      ],
      opened: -1,
    }
  },
  methods: {
    check(i) {
      if (this.opened === -1 && i === 0) {
        return true;
      }
      return this.opened === i;
    }
  }
}
</script>
