var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-wrapper"},[_c('div',{staticClass:"container footer-content"},[(!this.isMobile)?_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-5 info-wrapper mt-60"},[_vm._m(1),_c('div',{staticClass:"info"},[_c('h3',[_vm._v("Resources")]),_vm._m(2),_c('router-link',{attrs:{"to":{ name: 'whitepaper' }}},[_c('p',[_vm._v("Whitepaper")])]),_vm._m(3),_vm._m(4),_c('router-link',{attrs:{"to":{ name: 'term' },"tag":"a"}},[_c('p',[_vm._v("Privacy & Terms")])]),_vm._m(5)],1)]),_vm._m(6)]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 info-wrapper mt-60"},[_vm._m(7),_c('div',{staticClass:"info"},[_c('h3',[_vm._v("Resources")]),_vm._m(8),_c('router-link',{attrs:{"to":{ name: 'whitepaper' }}},[_c('p',[_vm._v("Whitepaper")])]),_vm._m(9),_vm._m(10),_c('router-link',{attrs:{"to":{ name: 'term' },"tag":"a"}},[_c('p',[_vm._v("Privacy & Terms")])]),_vm._m(11)],1)]),_vm._m(12)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3 mt-60"},[_c('div',{staticClass:"details"},[_c('img',{attrs:{"src":require("../assets/svg/footer-logo-tm.svg"),"alt":"RoboInu Logo","width":"180","height":"135"}}),_c('p',{staticClass:"copy-right mt-4"},[_vm._v("Copyright © 2021-2024 Robo Global Investment. All Rights Reserved")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('h3',[_vm._v("Community")]),_c('a',{attrs:{"href":"https://t.me/robo_inu","target":"_blank"}},[_c('p',[_vm._v("Telegram")])]),_c('a',{attrs:{"href":"https://twitter.com/RGI_info","target":"_blank"}},[_c('p',[_vm._v("Twitter")])]),_c('a',{attrs:{"href":"https://roboglobal.medium.com/","target":"_blank"}},[_c('p',[_vm._v("Medium")])]),_c('a',{attrs:{"href":"https://www.facebook.com/groups/318598893018719","target":"_blank"}},[_c('p',[_vm._v("Facebook")])]),_c('a',{attrs:{"href":"https://www.reddit.com/r/roboinu","target":"_blank"}},[_c('p',[_vm._v("Reddit")])]),_c('div',{staticClass:"mt-4"},[_c('h3',[_vm._v("Support")]),_c('a',{attrs:{"href":"mailto:support@roboglobal.info"}},[_c('p',[_vm._v("support@roboglobal.info")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.dextools.io/app/ether/pair-explorer/0xb49d78203e9dca6e1dd758ba8ddadbf8fd29c263","target":"_blank"}},[_c('p',[_vm._v("Price chart")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://etherscan.io/token/0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b"}},[_c('p',[_vm._v("Contract")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://github.com/roboinu/roboinu-contracts/blob/main/audit/ROBO%20INU%20Full%20Smart%20Contract%20Security%20Audit.pdf"}},[_c('p',[_vm._v("Security Audit")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h3',[_vm._v("Follow us")]),_c('div',{staticClass:"community-icon"},[_c('a',{attrs:{"href":"https://www.instagram.com/roboglobal_info","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/svg/instagram.svg"),"alt":"RGI Instagram","width":"32","height":"32"}})]),_c('a',{attrs:{"href":"https://twitter.com/RGI_info","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/svg/twitter.svg"),"alt":"RGI Twitter","width":"32","height":"32"}})]),_c('a',{attrs:{"href":"https://www.youtube.com/c/RoboInuFinance","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/svg/youtube.svg"),"alt":"RGI Reddit","width":"32","height":"32"}})]),_c('a',{attrs:{"href":"https://www.facebook.com/groups/318598893018719","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/svg/facebook.svg"),"alt":"RGI Facebook","width":"32","height":"32"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 mt-60"},[_c('h3',[_vm._v("Download Now")]),_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.roboglobal.wallet"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/svg/download_android_store.svg"),"alt":"Android Store Download"}})]),_c('a',{attrs:{"href":"https://apps.apple.com/vn/app/rgi-wallet/id1659875563"}},[_c('img',{staticClass:"img-fluid mt-n5 mb-n5",attrs:{"src":require("../assets/svg/download_ios_store.svg"),"alt":"Apple Store Download"}})]),_c('a',{attrs:{"href":"https://chromewebstore.google.com/detail/rgi-wallet/jhckpnnnpdipkakahjjhlpnieglnjcic"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/img/chrome_store_download.png"),"width":"270","alt":"Chrome Store Download"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('h3',[_vm._v("Community")]),_c('a',{attrs:{"href":"https://t.me/robo_inu","target":"_blank"}},[_c('p',[_vm._v("Telegram")])]),_c('a',{attrs:{"href":"https://twitter.com/RGI_info","target":"_blank"}},[_c('p',[_vm._v("Twitter")])]),_c('a',{attrs:{"href":"https://roboglobal.medium.com/","target":"_blank"}},[_c('p',[_vm._v("Medium")])]),_c('a',{attrs:{"href":"https://www.facebook.com/groups/318598893018719","target":"_blank"}},[_c('p',[_vm._v("Facebook")])]),_c('a',{attrs:{"href":"https://www.reddit.com/r/roboinu","target":"_blank"}},[_c('p',[_vm._v("Reddit")])]),_c('div',{staticClass:"mt-4"},[_c('h3',[_vm._v("Support")]),_c('a',{attrs:{"href":"mailto:support@roboglobal.info"}},[_c('p',[_vm._v("support@roboglobal.info")])]),_c('div',{staticClass:"community-icon"},[_c('a',{attrs:{"href":"https://www.instagram.com/roboglobal_info","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/svg/instagram.svg"),"alt":"RGI Instagram","width":"32","height":"32"}})]),_c('a',{attrs:{"href":"https://twitter.com/RGI_info","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/svg/twitter.svg"),"alt":"RGI Twitter","width":"32","height":"32"}})]),_c('a',{attrs:{"href":"https://www.youtube.com/c/RoboInuFinance","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/svg/youtube.svg"),"alt":"RGI Reddit","width":"32","height":"32"}})]),_c('a',{attrs:{"href":"https://www.facebook.com/groups/318598893018719","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/svg/facebook.svg"),"alt":"RGI Facebook","width":"32","height":"32"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.dextools.io/app/ether/pair-explorer/0xb49d78203e9dca6e1dd758ba8ddadbf8fd29c263","target":"_blank"}},[_c('p',[_vm._v("Price chart")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://etherscan.io/token/0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b"}},[_c('p',[_vm._v("Contract")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://github.com/roboinu/roboinu-contracts/blob/main/audit/ROBO%20INU%20Full%20Smart%20Contract%20Security%20Audit.pdf"}},[_c('p',[_vm._v("Security Audit")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('img',{attrs:{"src":require("../assets/svg/footer-logo-tm.svg"),"alt":"RoboInu Logo","width":"135","height":"110"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mt-60"},[_c('div',{staticClass:"details"},[_c('p',{staticClass:"copy-right"},[_vm._v("Copyright © 2021-2024 Robo Global Investment. All Rights Reserved.")])])])
}]

export { render, staticRenderFns }