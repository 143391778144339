<template>
  <div class="our-community-block">
    <div class="img-wrapper d-none d-lg-block">
      <div class="bg"></div>
      <img rel="preload" src="../../assets/svg/ROBOINU-RGB-Community.svg" alt="RoboInu Rocket">
    </div>
    <div class="info">
      <h5 class="sub-title text-left">OUR COMMUNITY</h5>
      <h4 class="hight-light">You decide what’s important.</h4>
      <p class="text-block">All big decisions in ROBO INU FINANCE are guided and voted by the people. Our #RoboWarrior community
        has been driving this project since November 2021.</p>
      <div class="icon">
        <a href="https://t.me/robo_inu" target="_blank">
          <img src="../../assets/svg/new-telegram.svg" alt="Roboinu Telegram">
        </a>
        <a href="https://twitter.com/RGI_info" target="_blank">
          <img src="../../assets/svg/new-twitter.svg" alt="Roboinu Twitter">
        </a>
        <a href="https://www.reddit.com/r/roboinu/" target="_blank">
          <img src="../../assets/svg/new-reddit.svg" alt="Roboinu Reddit">
        </a>
        <a href="https://www.facebook.com/groups/318598893018719" target="_blank">
          <img src="../../assets/svg/new-facebook.svg" alt="Roboinu Facebook">
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
