<template>
  <div class="readmore-item-wrapper">
    <TsParticles></TsParticles>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="header">
            <img src="../assets/svg/solution-bg.svg" alt="Background Solution" class="bg-header d-none d-lg-block">
            <h1 class="sub-title title">What’s the solution?</h1>
          </div>
          <p class="sub-title-priv">Who are we and why does Robo Global Investment exist?</p>
          <div class="content-wrapper mt-90 mb-4">
            <img src="../assets/svg/solution-business.svg" alt="Solution Business" class="d-none d-lg-block">
            <div class="text-wrapper">
              <p class="description">Assessing the trend of the world as described above, Robo Global Investment (RGI)
                is a company which has developed an ecosystem of valuable products and practical applications. RGI was
                born with the goal to provide solutions which can help solve problems using blockchain technology. RGI
                is not just a crypto project. RGI’s agenda is to provide valuable fintech products, with the common goal
                of helping individuals, families and businesses to obtain financial freedom. We will optimize these
                goals with products using blockchain technology that links with our daily life application to create a
                meaningful purpose for every person, organization and community that joins us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TsParticles from '@/components/TsParticles'

export default {
  name: "solution-page",
  components: {
    TsParticles,
  }
}
</script>
