<template>
  <div>
    <modal v-if="isShowModal" @close="onClose">
      <div slot="header" class="modal-detail-header">
        <img :src="getSvgUrl(item.img)" :alt="item.title" />
        <h3 class="title">{{ item.title }}</h3>
        <h5 class="summary">{{ item.summary }}</h5>
      </div>
      <div slot="body" class="modal-body-detail">
        <p class="description" v-html="item.descriptionDetail"></p>
        <div class="content-action" v-if="item.title === `Robo`">
          <p class="content">
            RGIP-01: Advance RBIF Allocation for Small-scale Liquidity Mining
            Activities
          </p>
          <div>
            <a href="https://dashboard.roboglobal.info" target="_blank">
              <button class="btn-countdown">
                <img :src="getSvgUrl('timer.svg')" :alt="item.title" />{{
                  timerOutput
                }}
              </button>
            </a>
            <a href="https://dashboard.roboglobal.info" target="_blank">
              <button class="btn-active">Active</button>
            </a>
          </div>
        </div>
      </div>
      <div slot="footer" class="modal-footer-detail">
        <div v-if="item.title === `RoboDAO`" class="action-footer">
          <a
            href="https://app.uniswap.org/swap?exactField=output&outputCurrency=0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b&inputCurrency=eth"
            target="_blank"
            ><button class="btn-buy">Buy $RBIF</button>
          </a>
          <a href="https://dashboard.roboglobal.info" target="_blank"
            ><button class="dashboard">Enter RoboDAO</button>
          </a>
        </div>
        <div v-if="item.title === `RoboWallet`" class="action-footer">
<!--          <a href="https://dashboard.roboglobal.info" target="_blank"></a>-->
          <button class="dashboard">Testing now</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Modal from "@/components/modal/Modal.vue";
import { mapState, mapGetters, mapActions } from "vuex";
export default Vue.extend({
  components: {
    Modal,
  },
  props: {
    isShowModal: Boolean,
    item: Object,
  },
  data() {
    return {
      timerOutput: "",
    };
  },
  updated() {},
  mounted() {
    setInterval(() => {
      this.startTimer();
    }, 1000);
  },
  methods: {
    ...mapActions([]),
    onClose() {
      this.$emit("onClose");
    },
    getSvgUrl(svg: string) {
      return require("@/assets/svg/" + svg);
    },
    startTimer: function () {
      const timeNow = new Date().getTime();
      const timeDifference = this.item.countDownToTime - timeNow;
      const millisecondsInOneSecond = 1000;
      const millisecondsInOneMinute = millisecondsInOneSecond * 60;
      const millisecondsInOneHour = millisecondsInOneMinute * 60;
      const millisecondsInOneDay = millisecondsInOneHour * 24;
      const differenceInDays = timeDifference / millisecondsInOneDay;
      const remainderDifferenceInHours =
        (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
      const remainderDifferenceInMinutes =
        (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
      const remainingDays = Math.floor(differenceInDays);
      const remainingHours = Math.floor(remainderDifferenceInHours);
      const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
      this.timerOutput =
        remainingDays +
        " days " +
        remainingHours +
        "h " +
        remainingMinutes +
        "m " +
        " Left";
    },
  },
  computed: {
    ...mapGetters([]),
    ...mapState({}),
  },
});
</script>
