<template>
  <Particles id="tsparticles" :options="particlesCfg" :particlesInit="particlesInit"
             :particlesLoaded="particlesLoaded"/>
</template>
<script>
import particlesCfg from "@/particles";
import {loadFull} from "tsparticles";

export default {
  name: 'TsParticles',
  data() {
    return {
      particlesCfg
    }
  },
  methods: {
    async particlesInit(engine) {
      await loadFull(engine);
    },
    async particlesLoaded(container) {
      console.log("Particles container loaded", container);
    },
  }
}
</script>
