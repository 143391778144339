<template>
  <div class="linktree">
    <div class="avatar-cover-img">
      <div class="container">
        <div class="avatar-field">
          <div class="profile-name">
            <img class="avatar-img" src="../assets/img/linktree/Avatar.png" alt="RoboGlobal Investment Avatar"/>
            <h2>RoboInu</h2>
            <img class="check" src="../assets/img/linktree/check.png" alt="RGI Verify"/>
          </div>
          <p class="about mt-5">
            <a target="_blank" href="https://twitter.com/search?q=%23RoboInu&src=hashtag_click">
              #RoboInu
            </a>
            <a target="_blank" href="https://twitter.com/search?q=%24RBIF&src=cashtag_click">
              $RBIF
            </a>
            creates a suite of defi all in one application with
            <a target="_blank" href="https://twitter.com/search?q=%23RoboWallet&src=hashtag_click">
              #RGIWallet,
            </a>
            <a target="_blank" href="https://twitter.com/search?q=%23RoboEx&src=hashtag_click">
              #RoboEx
            </a>
            and
            <a target="_blank" href="https://twitter.com/search?q=%23RoboDAO&src=hashtag_click">
              #RoboDAO
            </a>.
            <br>
            Telegram <a target="_blank" href="https://t.me/robo_inu">
            https://t.me/robo_inu
          </a>
          </p>
          <div class="button-field">
            <a href="https://twitter.com/RGI_info" class="button follow-button">Follow</a>
            <!--            <button class="button connect-wallet-button">Connect Wallet</button>-->
          </div>
        </div>
      </div>
    </div>
    <div class="bg">
      <div class="container">
        <div class="official-link transaction">
          <h4 class="official-link-title">OFFICIAL LINKS</h4>
          <div class="info"
               v-for="(value, index) in library"
               :key="index"
          >
            <a class="social-link" :href="value.url">
              <div class="name">
                <img :src="getIcongUrl(value.icon)" alt="Linktree Icon Social"/>
                <b>{{ value.name }}</b>
                <p></p>
              </div>
              <div class="link">
                <p class="text">{{ shortLink(value.url) }}</p>
                <img class="arrow-icon" src="../assets/img/linktree/arrow-right.png" alt="Linktree Arrow"/>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="as-features-on transaction mt-48">
          <h4>AS FEATURED ON</h4>
          <div class="list-logo">
            <img v-for="(value, index) in logoFeatured"
                 :key="index"
                 :src="getIcongUrl(value.icon)"
            />
          </div>
        </div>
      </div>
    </div>

    <img class="icon-bg icon-left-1" src="../assets/img/linktree/Icon-left-1.png"/>
    <img class="icon-bg icon-left-2" src="../assets/img/linktree/Icon-left-2.png"/>
    <img class="icon-bg icon-left-3" src="../assets/img/linktree/Icon-left-3.png"/>
    <img class="icon-bg icon-right-1" src="../assets/img/linktree/Icon-right-1.png"/>
    <img class="icon-bg icon-right-2" src="../assets/img/linktree/Icon-right-2.png"/>
    <img class="icon-bg icon-right-3" src="../assets/img/linktree/Icon-right-3.png"/>
  </div>
</template>

<script>

export default {
  title: 'Robo Global Investment - Link Tree',
  components: {},
  data() {
    return {
      library: [
        {
          name: "Website",
          url: "https://roboglobal.info",
          icon: "website",

        }, {
          name: "RoboEx",
          url: "https://dex.roboglobal.info",
          icon: "RoboEx",

        },{
          name: "RoboDashboard",
          url: "https://dashboard.roboglobal.info",
          icon: "dashboard",

        },{
          name: "Download RGI Wallet iOS App",
          url: "https://apps.apple.com/vn/app/rgi-wallet/id1659875563",
          icon: "apple",

        },{
          name: "Download RGI Wallet Android App",
          url: "https://play.google.com/store/apps/details?id=com.roboglobal.wallet",
          icon: "android",

        },
        {
          name: "Telegram",
          url: "https://t.me/robo_inu",
          icon: "telegram",

        }, {
          name: "CoinMarketCap",
          url: "https://coinmarketcap.com/currencies/robo-inu-finance",
          icon: "coin",

        }, {
          name: "CoinGecko",
          url: "https://www.coingecko.com/en/coins/robo-inu-finance",
          icon: "coingetko",

        }, {
          name: "Medium",
          url: "https://roboglobal.medium.com",
          icon: "medium",

        }, {
          name: "LinkedIn",
          url: "https://www.linkedin.com/company/robo-inu",
          icon: "linkedin",

        }, {
          name: "Threads",
          url: "https://www.threads.net/@roboglobal_info",
          icon: "threads",

        },
        {
          name: "Tiktok",
          url: "https://www.tiktok.com/@roboglobal_info?lang=en",
          icon: "tiktok"
        },
        {
          name: "Reddit",
          url: "https://www.reddit.com/r/roboinu/",
          icon: "reddit"
        },
        {
          name: "Github",
          url: "https://github.com/roboinu",
          icon: "github"
        },
        {
          name: "Twitter",
          url: "https://twitter.com/RGI_info",
          icon: "twitter"
        },
        {
          name: "Instagram",
          url: "https://www.instagram.com/roboglobal_info/",
          icon: "instagram"
        },
        {
          name: "Facebook",
          url: "https://www.facebook.com/groups/318598893018719/",
          icon: "facebook"
        },
        {
          name: "Youtube",
          url: "https://www.youtube.com/@RoboInuFinance",
          icon: "youtube"
        },
        {
          name: "Discord",
          url: "https://discord.gg/AfcS9e9zEf",
          icon: 'discord'
        },
        // {
        //   name: "Drive",
        //   url: "https://www.threads...",
        //   icon: 'drive'
        // }
      ],
      logoFeatured: [
        {
          icon: 'yahoo'
        },
        {
          icon: 'benzinga'
        },
        {
          icon: 'bloomberg'
        },
        {
          icon: 'blockster'
        },
        {
          icon: 'blockchaninwire'
        },
        {
          icon: 'bitcoininsider'
        },
        {
          icon: 'theamericanreporter'
        },
        {
          icon: 'android'
        },
        {
          icon: 'apple'
        },
        {
          icon: 'dashboard'
        },
        {
          icon: 'RoboEx'
        },
      ]
    }
  },

  methods: {
    getIcongUrl(img) {
      return require("../assets/img/linktree/" + img + ".png");
    },
    shortLink(link) {
      if (link.length > 25) {
        return `${link.substring(0, 25)}...`;
      }
      return link
    }
  },
}
</script>
