<template>
    <div class="readmore-item-wrapper term-wrapper">
        <TsParticles></TsParticles>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="header">
                        <img src="../assets/svg/policy.svg" alt="Policy Solution" class="bg-header d-none d-lg-block">
                        <h1 class="sub-title title">TERMS AND CONDITIONS OF USE</h1>
                    </div>
                    <div class="policy-block">
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="description"> These Terms and Conditions of Use (these "Terms") are between
                                    you
                                    (also referred to herein as "user", "you" and "your") and Robo Global Investment Pte
                                    Ltd., a company incorporated in [jurisdiction] ("RGI", "we", "us" and "our"). These
                                    Terms govern your use of the services provided by RGI described below (the
                                    "Services").
                                    By accessing the Services made available on https://roboglobal.info/ and browser
                                    plugin
                                    (the "Website") you agree that you have read, understand, and accept all of the
                                    terms
                                    and conditions contained in these Terms.<br><br>
                                    We may make changes to these Terms from time to time. If we do this, we will post
                                    the
                                    revised Terms on the Website and will indicate at the top of this page the date the
                                    was
                                    last revised. You understand and agree that your continued use of the Service or the
                                    Website after we have made any such changes constitutes your acceptance of the new
                                    Terms.
                                </p>
                            </div>
                        </div>
                        <!--                        1.INTRODUCTION-->
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="title">INTRODUCTION</p>
                                <p class="title"><span class="fs-1-5">Eligibility</span></p>
                                <p class="description">To be eligible to use the Website and Services, you must (a) be
                                    at least eighteen (18) years of age or older; (b) not be a Restricted Person (as
                                    defined below). If you are a Restricted Person, do not attempt to access or use the
                                    Website. RGI may but is not obligated to implement technical measures such as
                                    "geo-blocking" to ensure that the Website, interface and Services are not available
                                    to Restricted Persons. Use of a virtual private network (e.g., a VPN) or other means
                                    by Restricted Persons to access or use the Website, interface or Services is
                                    prohibited.<br><br>
                                    For the purpose of these Terms:<br>
                                    “Restricted Person” shall mean any person that (a) resides in, a citizen of, is
                                    incorporated in, or has a registered office in any Restricted Territory, as defined
                                    below; and (b) is listed on, or owned or controlled by a person or persons listed
                                    on, or acting on behalf of a person or persons subject listed on, any Sanctions
                                    List.
                                </p>
                            </div>
                            <img src="@/assets/svg/logo-term.svg" class="d-none d-lg-block" alt="">
                        </div>
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="description">“Restricted Territory” shall mean (a) any state, country or
                                    region that is listed on any Sanctions List or is otherwise subject to sanctions
                                    enforced by the United States, the United Nations, the United Kingdom or the
                                    European Union (including but not limited to, Myanmar (Burma), Cote D'Ivoire (Ivory
                                    Coast), Cuba, Crimea and Sevastopol, Democratic Republic of Congo, Iran, Iraq,
                                    Libya, Mali, Nicaragua, Democratic People’s Republic of Korea (North Korea),
                                    Somalia, Sudan, Syria, Yemen, Zimbabwe); and (b) [*].
                                    “Sanctions List” includes the “Specially Designated Nationals and Blocked Persons”,
                                    “Consolidated Sanctions” and “Sanctions Programs and Country Information” lists
                                    maintained by the Office of Foreign Assets Control of the US Department of Treasury
                                    (OFAC), the “Consolidated List of Financial Sanctions Targets” and the “Investment
                                    Ban List” maintained by Her Majesty's Treasury (HMT), or any similar list maintained
                                    by, or public announcement or designation made by any of (a) the United States
                                    government; (b) the United Nations Security Council; (c) the United Kingdom
                                    government; or (d) the European Union.
                                </p>
                                <p class="title"><span class="fs-1-5">Suspension or Termination</span></p>
                                <p class="description">
                                    We reserve the right to disable access to the Website interface at any time in the
                                    event
                                    of any breach of the Terms, including without limitation, if we, in our sole
                                    discretion,
                                    believe that you, at any time, fail to satisfy the eligibility requirements set
                                    forth in
                                    the Terms. Further, we reserve the right to limit or restrict access to the Website
                                    interface by any person or entity, or within any geographic area or legal
                                    jurisdiction,
                                    at any time and at our sole discretion. We will not be liable to you for any losses
                                    or
                                    damages you may suffer as a result of or in connection with the Website interface
                                    being
                                    inaccessible to you at any time or for any reason.</p>
                                <p class="title"><span class="fs-1-5">Data and Information</span></p>
                                <p class="description">
                                    Where requested to provide any information about yourself, you agree to (i) provide
                                    accurate, current and complete information about you as may be prompted by any
                                    registration forms on the Website or otherwise requested by RGI ("Registration
                                    Data"); (ii) maintain and promptly update the Registration Data, and any other
                                    information you provide to RGI, to keep it accurate, current and complete; (iii)
                                    promptly notify RGI regarding any material changes to information or circumstances
                                    that could affect your eligibility to continue to use the Website or Service or the
                                    terms on which you use the Website or Service; and (iv) be fully responsible for all
                                    your actions on the Website.
                                </p>
                                <p class="title"><span class="fs-1-5">Interface</span></p>
                                <p class="description">
                                    The interface hosted on the Website provides a visual representation of the
                                    available Services and enables access to the RoboWallet. You are solely responsible
                                    for adhering to all laws and regulations applicable to you and your use or access to
                                    the Website and interface thereon. Your use of the Website and RoboWallet is
                                    prohibited by and otherwise violate or facilitate the violation of any applicable
                                    laws or regulations, or contribute to or facilitate any illegal activity. We make no
                                    representations or warranties that the information, products, or services provided
                                    through the Website, are appropriate for access or use in other jurisdictions. We
                                    reserve the right to limit the availability of our Website to any person, geographic
                                    area, or jurisdiction, at any time and at our sole and absolute discretion.

                                </p>
                                <p class="title"><span class="fs-1-5">Access</span></p>
                                <p class="description">
                                    You agree and understand that you are not allowed to enter any restricted area of
                                    any computer or network of RGI under any circumstances, or perform any functions
                                    that are not authorised by these Terms.
                                </p>
                            </div>
                        </div>
                        <!--                        2.THE SERVICE-->
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="title">THE SERVICE</p>
                                <p class="title"><span class="fs-1-5">RoboWallet</span></p>
                                <p class="description">RoboWallet is a decentralised non-custodial electronic wallet
                                    application, which allows users to store, manage, monitor and engage in various
                                    transactions relating to supported digital assets. RoboWallet is intended solely for
                                    proper use with supported digital assets (as designated by RGI from time to time,
                                    which may in its sole discretion terminate support at any time for any particular
                                    digital asset). Under no circumstances should you attempt to utilise RoboWallet to
                                    store, send, request, or receive any assets other than supported digital assets. RGI
                                    assumes no responsibility in connection with any attempt to use RoboWallet with
                                    unsupported digital assets.
                                </p>
                            </div>
                            <img src="@/assets/svg/lauchpad-term.svg" class="d-none d-lg-block" alt="">
                        </div>
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="description">RGI sole provides technical software and does not own or control
                                    the underlying software protocols which govern the operation of digital assets and
                                    which users may interact with utilising RoboWallet. Generally, these underlying
                                    protocols are open source, and anyone can use, copy, modify, and distribute them.
                                    RGI assume no responsibility for the operation of the underlying protocols and do
                                    not guarantee the functionality or security of network operations. In particular,
                                    the underlying protocols may be subject to sudden changes in operating rules
                                    (including “forks”). Any such operating changes may materially affect the
                                    availability, value, functionality, and/or the name of your digital
                                </p>
                                <p class="title"><span class="fs-1-5">Custody and title to assets</span></p>
                                <p class="description">
                                    When you access certain features of the Services, the user interface will allow you
                                    to access a non-custodial smart contract to perform a variety of transactions. In
                                    particular, you confirm that all actions and functions performed via the RoboWallet
                                    smart contract are irrevocable. You remain in full control of your digital assets,
                                    which are not held or controlled in any way by RGI. RGI does not collect or hold
                                    your keys or information - accordingly, if you lose control over these assets, RGI
                                    cannot access your digital assets; digital backups; recover keys, passwords, or
                                    other information; reset passwords; or reverse transactions. An encrypted backup of
                                    certain information associated with the RoboWallet can be stored on your device in
                                    Keystore JSON format. The private key is connected to your digital asset address
                                    and, together, they can be used to authorize the transfer of digital assets to and
                                    from that digital asset address. You are solely responsible for the safety of your
                                    digital assets and your use of the Services, including without limitation for
                                    storing, backing up, and maintaining the confidentiality of your private keys,
                                    passwords, and information, and for the security of any transactions you perform
                                    using the Website. You expressly relieve and release RGI from any and all liability
                                    and/or loss arising from your use of the Services.<br><br>
                                    RoboWallet is a non-custodial protocol, so title to all supported digital assets are
                                    held directly in your own name as your property, and you shall not transfer the same
                                    to RGI. As owner of supported digital assets, you shall bear all risk of loss of
                                    such supported digital assets. RGI shall have no liability for supported digital
                                    asset fluctuations or any loss.</p>
                                <p class="title"><span class="fs-1-5">Interactions with Third Party Service Providers / Protocols</span>
                                </p>
                                <p class="description">
                                    [The Services may incorporate, integrate or may provide access to, applications or
                                    materials that are hosted by another party, including, but not limited to: (a)
                                    blockchain networks (such as Bitcoin, Ethereum and Solana); (b) information
                                    providers or oracles (for price data, currency exchange information, market data,
                                    and other relevant information); (c) various exchanges, liquidity or lending
                                    protocols; and (d) payment solutions or other service providers. Please note the
                                    provisions and disclaimers in Clauses 4.1 and 10.3 below.
                                    <br><br>
                                    You are informed and hereby acknowledge that in relation to the functionalities of
                                    the RoboWallet (i.e. the transfer, purchase and swap functions), all transactions
                                    are conducted through either the relevant blockchain network (for the transfer
                                    function), the relevant exchange or liquidity protocols (for the swap function) or
                                    the relevant service provider chosen by you (for the purchase function).
                                    <br><br>
                                    You acknowledge that we will have no insight into or control over these payments or
                                    transactions, nor do we have the ability to reverse any transactions and that you
                                    are contracting directly with your counterparty on a peer-to-peer basis through the
                                    relevant blockchain or protocol (for the transfer or swap function) and with the
                                    relevant service provider (for the purchase function). Accordingly, we will have no
                                    liability to you or to any third party for any claims or damages that may arise as a
                                    result of any transactions that you engage in via the Website, or using the smart
                                    contracts, or any other transactions that you conduct via the relevant blockchain
                                    network.
                                </p>
                                <p class="title"><span class="fs-1-5">Experimental features</span></p>
                                <p class="description">
                                    In order to test new features and functionalities, we may release certain
                                    experimental features on RoboWallet from time to time. These features may not be
                                    complete and may have not been fully tested, which may present heightened risks.
                                    They may contain errors or inaccuracies that could cause failures, corruption or
                                    loss of data and/or information. RGI does not guarantee the stability,
                                    functionality, or long-term support of these features, and we do not recommend to
                                    use these features unless you have strong technical skillsand fully understand the
                                    implications of your actions. You expressly acknowledge and agree that use of
                                    RoboWallet (including any experimental feature) is at your sole risk.
                                </p>
                                <p class="title"><span class="fs-1-5">Service fees</span></p>
                                <p class="description">
                                    The relevant blockchain network typically requires the payment of a transaction fee
                                    ("Gas Fee") for every transaction that occurs on the relevant blockchain network.
                                    The Gas Fee funds the network of computers that run the decentralised network. This
                                    means that you will need to pay a Gas Fee for each transaction that occurs via the
                                    Website.<br><br>
                                    You also may be subject to certain additional fees and commissions, including fees
                                    for transferring, withdrawing or trading digital assets on RoboWallet or on the
                                    Website, as notified to you prior to performing any transaction thereon or otherwise
                                    interacting with RoboWallet or the smart contracts. RGI also reserves the right to
                                    levy additional fees for access via the smart contracts, or the Website in the
                                    future. You agree to promptly pay all aforementioned fees and commissions.
                                </p>
                                <p class="title"><span class="fs-1-5">Not an Offering of Banking business, Trust business, Custodial business, Escrow business, Securities or Commodities</span>
                                </p>
                                <p class="description">
                                    You understand and affirm that RGI is a non-custodial provider of technical
                                    smart-contract services which allow users to manage their digital assets. The
                                    content of the Website and the Services do not constitute any banking business,
                                    trust business, custodial business, escrow business, any offer to buy or sell, or a
                                    solicitation of an offer to buy or sell investments, securities, partnership
                                    interests, commodities or any other financial instruments in any jurisdiction. The
                                    content or the Website and the Services also do not constitute, and may not be used
                                    for or in connection with, an offer or solicitation by anyone in any state or
                                    jurisdiction in which such an offer or solicitation is not authorized or permitted,
                                    or to any person to whom it is unlawful to make such offer or solicitation. In
                                    particular, the Services do not constitute any "banking business" within the meaning
                                    of any banking laws, "custody" within the meaning of any virtual assets law, or
                                    "capital markets products" or "securities" within the meaning of any securities law.
                                </p>
                                <p class="title"><span class="fs-1-5">No Advice</span>
                                </p>
                                <p class="description">
                                    RGI makes no representation or warranty, express or implied, to the extent not
                                    prohibited by applicable law, regarding the advisability of participating in digital
                                    assets on any blockchain, any financial products, securities, funds, commodity
                                    interests, partnership interests or other investments or funding or purchasing
                                    loans. RGI is merely a technology service provider allowing you to manage your own
                                    digital assets connecting you with various third parties and does not offer
                                    fiduciary services, and is not your agent, trustee, advisor or fiduciary.
                                </p>
                                <p class="title"><span class="fs-1-5">Taxes</span>
                                </p>
                                <p class="description">
                                    It is your sole responsibility to determine whether, and to what extent, any taxes
                                    apply to any interest received through the Services, and to withhold, collect,
                                    report and remit the correct amount of tax to the appropriate tax authorities.
                                </p>
                                <p class="title"><span class="fs-1-5">Rewards Program</span>
                                </p>
                                <p class="description">
                                    RGI may from time to time introduce rewards programs and/or promotions to reward
                                    users who store $RBIF tokens in their RoboWallet. Separate terms and conditions
                                    shall apply.
                                </p>
                            </div>
                        </div>
                        <!--                        3.CONDITIONS OF USE-->
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="title">CONDITIONS OF USE</p>
                                <p class="description">You agree to use the Website and/or the Services, including all
                                    features and functionalities associated therewith, in accordance with all applicable
                                    laws, rules and regulations, or other restrictions on use of the service or content
                                    therein and subject further to the following conditions:<br><br>
                                </p>
                                <p class="description ml-4">(A)&emsp;Unlawful Activity: you agree not to engage, or
                                    assist, in
                                    any activity that violates
                                    any law, statute, ordinance, regulation, or sanctions program, including but not
                                    limited to the U.S. Department of Treasury’s Office of Foreign Assets Control
                                    (OFAC), or that involves proceeds of any unlawful activity.
                                </p>
                                <p class="description ml-4">(B)&emsp;Abusive Activity: you agree not to engage in any
                                    activity that poses a threat to RGI
                                    or the Website, for example by distributing a virus or other harmful code, or
                                    through unauthorized access to the Website or other users’ digital assets. you shall
                                    not use the Website in any manner that could damage, disable, overburden, impair or
                                    otherwise interfere with any of the Company’s server, or the networks connected to
                                    any Company server, or interfere with any other party’s access and use of the
                                    Website.
                                </p>
                                <p class="description ml-4">(C)&emsp;Inappropriate Behaviour: you agree not to interfere
                                    with other users’ access to or
                                    use of the Services.
                                </p>
                                <p class="description ml-4">(D)&emsp;Communication: you agree not to communicate with
                                    other users for purposes of (1)
                                    sending unsolicited advertising or promotions, requests for donations, or spam; (2)
                                    harassing or abusing other users; (3) interfering with transactions of other users.
                                    You agree not to use data collected from the Website to contact individuals,
                                    companies, or other persons or entities outside the Website for any purpose,
                                    including but not limited to marketing activity.
                                </p>
                                <p class="description ml-4">(E)&emsp;Fraud: you agree not to engage in any activity
                                    which operates to defraud RGI, other
                                    users, or any other person; or to provide any false, inaccurate, or misleading
                                    information to RGI.
                                </p>
                                <p class="description ml-4">(F)&emsp;Gambling: you agree not to utilize the Services to
                                    engage in any lottery, bidding
                                    fee auctions, contests, sweepstakes, or other games of chance.
                                </p>
                                <p class="description ml-4">(G)&emsp;Unfair competition. you agree not to access the
                                    Website or use the Services in order
                                    to build a similar or competitive site, services, or software.
                                </p>
                            </div>
                        </div>
                        <!--                        4.RISK FACTORS-->
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="title">RISK FACTORS</p>
                                <p class="description">You acknowledge and agree that the Services are is currently in
                                    the initial development stages and there are a variety of unforeseeable risks with
                                    utilising the Services or participating in the platform, acquiring, holding or using
                                    native RGI tokens. In the worst scenario, this could lead to the loss of all or part
                                    of your digital assets associated with the Services. <strong
                                        style="font-family: 'Neue Haas Unica Bold'">IF YOU DECIDE TO UTILISE
                                        SERVICES OR ACQUIRE THE NATIVE TOKENS ($RBIF) YOU EXPRESSLY ACKNOWLEDGE, ACCEPT
                                        AND
                                        ASSUME THE BELOW RISKS AND AGREE NOT TO HOLD RGI OR ANY OF THEIR RELATED PARTIES
                                        RESPONSIBLE FOR THE FOLLOWING RISKS:</strong>
                                </p>
                            </div>
                        </div>
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="title"><span class="fs-1-5">Third-party Risk</span></p>
                                <p class="description">
                                    The Services rely on whole or partly, on third-party software and the continued
                                    development and support of third parties. There is no assurance or guarantee that
                                    those third parties will maintain their support of their software, which might have
                                    a material adverse effect on the Services.
                                    <br><br>Further, when using the purchase function, you may be exposed to
                                    counterparty risk if there is any default, insolvency or failure to perform on the
                                    part of the relevant service provider you have chosen to purchase the relevant
                                    digital asset through.
                                </p>
                                <p class="title"><span class="fs-1-5">No Insurance</span></p>
                                <p class="description">
                                    Digital assets are not legal tender, are not backed by the government, and are not
                                    subject to any deposit insurance schemes or protections under any banking or
                                    securities laws. RGI is not a bank and does not offer fiduciary services, nor does
                                    it offer any security broking services.
                                </p>
                                <p class="title"><span class="fs-1-5">New Technical Risk</span></p>
                                <p class="description">
                                    The RGI software used for RGI is new. While this software has been extensively
                                    tested, the software used for the Services is still relatively new and could have
                                    bugs or security vulnerabilities. Further, the software is still under development
                                    and may undergo significant changes over time that may not meet users’ expectations.
                                </p>
                                <p class="title"><span class="fs-1-5">Smart Contract Risks</span></p>
                                <p class="description">
                                    The underlying smart contracts run on a variety of supported blockchain networks,
                                    using specially-developed smart contracts. Accordingly, upgrades to the relevant
                                    blockchain network, a hard fork in the relevant blockchain network, re-organisations
                                    of blockchain structure or blocks, or a change in how transactions are confirmed on
                                    the relevant blockchain network may have unintended, adverse effects on the smart
                                    contracts built thereon, including RGI software and smart contracts.
                                </p>
                                <p class="title"><span class="fs-1-5">Information Security Risk</span></p>
                                <p class="description">
                                    Digital assets, and use of the Services may be subject to expropriation and/or
                                    theft. Hackers or other malicious groups or organizations may attempt to interfere
                                    with the Services in a variety of ways, including, but not limited to, malware
                                    attacks, denial of service attacks, consensus-based attacks, Sybil attacks, smurfing
                                    and spoofing. Furthermore, because the underlying blockchain networks comprise
                                    open-source software, there is the software underlying the Services may contain
                                    intentional or unintentional bugs or weaknesses that may negatively affect the
                                    Services or result in the loss of the user’s digital assets, the loss of the user’s
                                    ability to access or control their digital assets. In the event of such a software
                                    bug or weakness, there may be no remedy, and users are not guaranteed any remedy,
                                    refund or compensation. </p>
                                <p class="title"><span class="fs-1-5">Regulatory risks</span></p>
                                <p class="description">
                                    The regulatory status of digital assets, and distributed ledger technology is
                                    unclear or unsettled in many jurisdictions. While every effort has been taken to
                                    ensure that the Services are compliant with local laws, it is difficult to predict
                                    how or whether regulatory agencies may apply existing regulation with respect to the
                                    Services. It is likewise difficult to predict how or whether legislatures or
                                    regulatory agencies may implement changes to law and regulation affecting
                                    distributed ledger technology and its applications, including the Services.
                                    Regulatory actions could negatively impact RGI in various ways, and thus the
                                    Services may not be available in certain areas. </p>
                                <p class="title"><span class="fs-1-5">Taxation Risk</span></p>
                                <p class="description">
                                    The tax characterization of digital assets, and the usage of the Services are
                                    uncertain. It is possible that the user's intended treatment of digital assets may
                                    be challenged. You must seek your own tax advice in connection with the Services
                                    provided by RGI, which may result in adverse tax consequences to you, including,
                                    without limitation, withholding taxes, transfer taxes, value-added taxes, income
                                    taxes and similar taxes, levies, duties or other charges and tax reporting
                                    requirements.
                                </p>
                                <p class="title"><span class="fs-1-5">Other Risks Associated with the ownership or trading of digital assets</span>
                                </p>
                                <p class="description">
                                    Trading in digital assets entails significant risks of financial loss. You should
                                    not commit funds to owning or trading in digital tokens that you are not prepared to
                                    lose entirely. Markets for digital assets are volatile and prices can fluctuate
                                    significantly, which could result in sudden and significant increases or decreases
                                    in the value of your assets at any given moment. You should assess whether your
                                    financial situation and risk tolerance is suitable for owning, buying, selling or
                                    trading digital tokens. You accept and agree that you are solely responsible for any
                                    decision to buy, sell, trade or otherwise hold or deal with digital assets. </p>
                            </div>
                        </div>
                        <!--                        5.WEBSITE AVAILABILITY AND ACCURACY-->
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="title">WEBSITE AVAILABILITY AND ACCURACY</p>
                                <p class="title"><span class="fs-1-5">Access and Availability</span></p>
                                <p class="description">
                                    Access to the Services may become degraded or unavailable on RGI during times of
                                    significant volatility or volume. This could result in the inability to interact
                                    with third-party services for periods of time and may also lead to support response
                                    time delays. Users will, however, be able to access these third-party services
                                    through other means. Although we strive to provide you with excellent service, we do
                                    not guarantee that the Website or Services will be available without interruption
                                    and we do not guarantee that requests to interact with third-party services will be
                                    successful.
                                </p>
                                <p class="title"><span class="fs-1-5">Website Accuracy</span></p>
                                <p class="description">
                                    Although we intend to provide accurate and timely information on the Website, the
                                    Website (including, without limitation, the content may not always be entirely
                                    accurate, complete or current and may also include technical inaccuracies or
                                    typographical errors. In an effort to continue to provide you with as complete and
                                    accurate information as possible, information may, to the extent permitted by
                                    applicable law, be changed or updated from time to time without notice, including
                                    without limitation information regarding our policies, products and services.
                                    Accordingly, you should verify all information before relying on it, and all
                                    decisions based on information contained on the Website are your sole responsibility
                                    and we shall have no liability for such decisions. Links to third-party materials
                                    (including without limitation any websites) may be provided as a convenience but are
                                    not controlled by us. You acknowledge and agree that we are not responsible for any
                                    aspect of the information, content, or services contained in any such third-party
                                    materials accessible or linked to from the Website. </p>
                            </div>
                        </div>
                        <!--                        6.CONSENT TO ELECTRONIC DISCLOSURES AND SIGNATURES-->
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="title">CONSENT TO ELECTRONIC DISCLOSURES AND SIGNATURES</p>
                                <p class="title"><span class="fs-1-5">General</span></p>
                                <p class="description">
                                    Because RGI operates only on the Internet, it is necessary for you to consent to
                                    transact business with us online and electronically. As part of doing business with
                                    us, therefore, we also need you to consent to our providing you certain disclosures
                                    electronically, either via our Website or to the email address (if applicable) you
                                    provide to us. By agreeing to these Terms, you agree to receive electronically all
                                    documents, communications, notices, contracts, and agreements arising from or
                                    relating to your use of the Website and Service.
                                </p>
                                <p class="title"><span class="fs-1-5">Communications</span></p>
                                <p class="description">By accepting these Terms, you expressly consent to be contacted
                                    by us, our agents, representatives, affiliates, or anyone calling on our behalf for
                                    any and all purposes, in any way, including SMS messages (including text messages),
                                    calls using pre-recorded messages or artificial voice, and calls and messages
                                    delivered using auto telephone dialling system or an automatic texting system.
                                    Notwithstanding the aforementioned, any form of communication from RGI will be
                                    provided to you electronically through the Website or (if applicable) via email to
                                    the email address provided. If you require paper copies of any agreements or
                                    disclosures, you may print such documents desired.</p>
                                <p class="title"><span class="fs-1-5">Scope of Consent</span></p>
                                <p class="description">Your consent to receive disclosures and transact business
                                    electronically, and our agreement to do so, applies to any transactions to which
                                    such disclosures relate, whether between you and RGI or a third party by and through
                                    the Service. Your consent will remain in effect for so long as you are a user and,
                                    if you are no longer a user, will continue until such a time as all disclosures
                                    relevant to Services received through the Website.</p>
                                <p class="title"><span class="fs-1-5">Withdrawing Consent</span></p>
                                <p class="description">You may withdraw your consent to receive agreements or
                                    disclosures electronically by contacting us at [*@*.com]. However, once you have
                                    withdrawn your consent you will not be able to access the Services.</p>
                            </div>
                        </div>
                        <!--                        7.INTELLECTUAL PROPERTY, COPYRIGHTS AND IDENTIFYING MARKS-->
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="title">INTELLECTUAL PROPERTY, COPYRIGHTS AND IDENTIFYING MARKS</p>
                                <p class="title"><span class="fs-1-5">RGI Intellectual Property</span></p>
                                <p class="description">
                                    You acknowledge that all Intellectual Property Rights in RGI smart contracts,
                                    RoboWallet, the Website, or any service/product thereon (including without
                                    limitation any information, licenses, business plans, data, patent disclosures,
                                    system applications, structures, models, flow charts, techniques, processes,
                                    compositions, compounds, software, programs, source code and object code, comments
                                    to the source or object code, specifications, documents, reports, presentations,
                                    test results, findings, ideas, knowhow, copyright, trade secrets, abstracts and/or
                                    summaries thereof) exclusively belongs and shall exclusively belong to RGI, and you
                                    shall have no rights in or to such Intellectual Property Rights.
                                    <br><br>
                                    To the extent any RGI intellectual property rights are deemed to belong to you, you
                                    hereby irrevocably assigns and transfers to RGI all right, title and interest in all
                                    such intellectual property rights, and agrees to execute all documents reasonably
                                    requested by RGI for the purpose of perfecting such assignment and/or transfer and
                                    applying for and obtaining any domestic and foreign patent and copyright
                                    registrations.
                                </p>
                                <p class="title"><span class="fs-1-5">Limited License</span></p>
                                <p class="description">All content on the Website, including but not limited to designs,
                                    text, graphics, pictures, video, information, software, music, sound and other
                                    files, and their selection and arrangement (the "Content"), are the proprietary
                                    property of RGI with all rights reserved. No Content may be modified, copied,
                                    distributed, framed, reproduced, republished, downloaded, displayed, posted,
                                    transmitted, or sold in any form or by any means, in whole or in part, without RGI's
                                    prior written permission, except as provided in the following sentence and except
                                    that the foregoing does not apply to your own User Content (as defined below) that
                                    you legally post on the Website. Provided that you are eligible for use of the
                                    Website, you are granted a limited license to access and use the Website and
                                    RoboWallet and to download or print a copy of any portion of the Content solely for
                                    your use in connection with your use of the Website or Service, provided that you
                                    keep all copyright or other proprietary notices intact. Except for your own User
                                    Content (as defined below), you may not republish Content on any Internet, Intranet
                                    or Extranet site or incorporate the information in any other database or
                                    compilation, and any other use of the Content is strictly prohibited. Any use of the
                                    Website or the Content other than as specifically authorized herein, without the
                                    prior written permission of RGI, is strictly prohibited and will terminate the
                                    license granted herein. Such unauthorized use may also violate applicable laws
                                    including without limitation copyright and trademark laws and applicable
                                    communications regulations and statutes. Unless explicitly stated herein, nothing in
                                    these Terms shall be construed as conferring any license to intellectual property
                                    rights, whether by estoppel, implication or otherwise. This license is revocable by
                                    us at any time without notice and with or without cause.</p>
                                <p class="title"><span class="fs-1-5">Trademarks</span></p>
                                <p class="description">RGI and other RGI graphics, logos, designs, page headers, button
                                    icons, scripts, and service names are registered trademarks, trademarks or trade
                                    dress of RGI in the British Virgin Islands, the U.S. and/or other countries. RGI's
                                    trademarks and trade dress may not be used, including as part of trademarks and/or
                                    as part of domain names, in connection with any product or service in any manner
                                    that is likely to cause confusion and may not be copied, imitated, or used, in whole
                                    or in part, without the prior written permission of RGI. RGI may, at its sole
                                    discretion, limit access to the Website by any users who infringe any intellectual
                                    property rights of RGI or others.</p>
                                <p class="title"><span class="fs-1-5">Copyright Complaints</span></p>
                                <p class="description">If you believe that any material on the Website infringes upon
                                    any copyright which you own or control, you may send a written notification of such
                                    infringement to RGI at [*@*.com].</p>
                                <p class="title"><span class="fs-1-5">Suggestions</span></p>
                                <p class="description">You acknowledge and agree that any questions, comments,
                                    suggestions, ideas, feedback or other information about the Website or the Service
                                    ("Suggestions"), provided by you to RGI are non-confidential and shall become the
                                    sole property of RGI. RGI shall own exclusive rights, including all intellectual
                                    property rights, and shall be entitled to the unrestricted use and dissemination of
                                    these Suggestions for any purpose, commercial or otherwise, without acknowledgment
                                    or compensation to you.</p>
                            </div>
                        </div>
                        <!--                        8.DATA PROTECTION AND SECURITY-->
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="title">DATA PROTECTION AND SECURITY</p>
                                <p class="title"><span class="fs-1-5">Loss or Compromise</span></p>
                                <p class="description">
                                    Any loss or compromise of your electronic device or your security details may result
                                    in unauthorized access to your digital assets by third parties and the loss or theft
                                    of such assets. You are reminded not store any password, seed phrases or other
                                    security details in any non-secure systems, such as third-party email services,
                                    which may be susceptible to security breaches and security incidents.
                                </p>
                                <p class="title"><span class="fs-1-5">Shared Access</span></p>
                                <p class="description">You should never allow remote access or share your computer
                                    screen with someone else when you are accessing the RoboWallet. RGI will never under
                                    any circumstances ask you for your private keys or passwords, or to screen share or
                                    otherwise seek to access your computer or digital assets. You should not provide
                                    your details to any third party for the purposes of remotely accessing your
                                    computer, devices or digital assets.</p>
                                <p class="title"><span class="fs-1-5">Personal Data</span></p>
                                <p class="description">You acknowledge that we may process personal data in relation to
                                    you (if you are an individual), and personal data that you have provided or in the
                                    future provide to us in relation to your employees and other associated or other
                                    individuals, in connection with these Terms, or the Services. Accordingly, you
                                    represent and warrant that: (i) your disclosure to us of any personal data relating
                                    to individuals other than yourself was or will be made in accordance with all
                                    applicable data protection and data privacy laws, and those data are accurate, up to
                                    date and relevant when disclosed; (ii) before providing any such personal data to
                                    us, you have read and understood our Privacy Policy, which is available [privacy
                                    policy], and, in the case of personal data relating to an individual other than
                                    yourself, have (or will at the time of disclosure have) provided a copy of that
                                    Privacy Policy (as amended from time to time), to that individual; and (iii) if from
                                    time to time we provide you with a replacement version of the Privacy Policy, you
                                    will promptly read that notice and provide a copy to any individual whose personal
                                    data you have provided to us.</p>
                                <p class="title"><span
                                    class="fs-1-5">Safety and Security of Your Computer and Devices</span></p>
                                <p class="description">RGI is not liable for any damage or interruptions caused by any
                                    computer viruses, malware or other malicious code that may affect your computer or
                                    other equipment, or any phishing, spoofing or other attacks. We advise that you use
                                    reputable virus screening and prevention software to protect your systems and/or
                                    devices.</p>
                            </div>
                        </div>
                        <!--                        9.USER FEEDBACK, QUERIES, COMPLAINTS, DISPUTES-->
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="title">USER FEEDBACK, QUERIES, COMPLAINTS, DISPUTES</p>
                                <p class="title"><span class="fs-1-5">Contact RGI</span></p>
                                <p class="description">
                                    If you have feedback or general questions, please contact us via our User Support at
                                    [*@*.com]. When you contact us please provide us with your name, email address, and
                                    any other information we may need to identify you, your transactions conducted, and
                                    digital assets held.
                                </p>
                                <p class="title"><span class="fs-1-5">Dispute Resolution</span></p>
                                <p class="description"><span style="font-family: 'Neue Haas Unica Bold'">PLEASE READ THIS SECTION CAREFULLY BECAUSE IT CONTAINS CERTAIN
                                    PROVISIONS, SUCH AS A BINDING ARBITRATION SECTION AND CLASS ACTION WAIVER, WHICH
                                    AFFECT YOUR LEGAL RIGHTS. THIS SECTION REQUIRES YOU TO ARBITRATE CERTAIN DISPUTES
                                    AND CLAIMS WITH RGI AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.</span>
                                    <br><br>
                                    Each party (i) waives all its respective right(s) to have any and all disputes,
                                    claims, suits, actions, causes of action, demands or proceedings (collectively,
                                    "Disputes") arising from or related to these Terms resolved in a court, and (ii)
                                    waive all its respective right(s) to have any Disputes heard before a court.
                                    Instead, each party shall arbitrate Disputes through binding arbitration (which is
                                    the referral of a Dispute to one or more persons charged with reviewing the Dispute
                                    and making a final and binding determination to resolve it instead of having the
                                    Dispute decided by a judge or jury in court).
                                    <br><br>
                                    Any Dispute arising out of or related to these Terms is personal to you and will be
                                    resolved solely through individual arbitration, and in no circumstances shall be
                                    brought as a class arbitration, class action or any other type of representative
                                    proceeding. There will be no class arbitration or arbitration in which an entity
                                    attempts to resolve a Dispute as a representative of another individual or group of
                                    individuals. Further, a Dispute cannot be brought as a class or other type of
                                    representative action, whether within or outside of arbitration, or on behalf of any
                                    other individual or group of individuals.
                                    <br><br>
                                    Any Dispute arising out of or in connection with these Terms (including without
                                    limitation the enforceability of this section or any question regarding its
                                    existence, validity or termination) shall be referred to and finally resolved by
                                    arbitration administered by [*]. The number of arbitrators shall be one. The
                                    language to be used in the arbitral proceedings shall be English.
                                    <br><br>
                                    Each party will notify the other party in writing of any Dispute within thirty (30)
                                    days of the date it arises, so that the Parties can attempt in good faith to resolve
                                    the Dispute informally. Notice to RGI shall be sent by e-mail to RGI at [*@*.com].
                                    Notice to you shall be either posted on the Website or, if available, will be sent
                                    by email to your email on record. Your notice must include (i) your name, postal
                                    address, email address and telephone number, (ii) a full and sufficient description
                                    of the nature or basis of the Dispute, and (iii) the specific relief that you are
                                    seeking. If you and RGI cannot agree on how to resolve the Dispute within thirty
                                    (30) days after the date the notice is received by the applicable party, then either
                                    you or RGI may, as appropriate and in accordance with this section, commence an
                                    arbitration proceeding or, to the extent specifically provided for in this section,
                                    file a claim in court.
                                    <br><br>
                                    The arbitrator does not have the authority to conduct a class arbitration or a
                                    representative or class action, which is prohibited by these Terms. The arbitrator
                                    may only conduct an individual arbitration and may not consolidate more than one
                                    individual’s claims, preside over any type of class or representative proceeding or
                                    preside over any proceeding involving more than one individual.
                                    <br><br>
                                    If any term, clause or provision of this section is held invalid or unenforceable,
                                    it will be held to the minimum extent applicable and required by law, and all other
                                    terms, clauses and provisions of this section will remain valid and enforceable.
                                    Further, the waivers set forth in this section are severable from the other
                                    provisions of these Terms and will remain valid and enforceable, except as
                                    prohibited by applicable law.
                                    <br><br>
                                    You agree that this section of these Terms has been included to rapidly and
                                    inexpensively resolve any disputes with respect to the matters described herein, and
                                    that this section shall be grounds for dismissal of any court action commenced by
                                    you with respect to a dispute arising out of such matters.
                                    <br><br>
                                    A printed version of these Terms shall be admissible in judicial or administrative
                                    proceedings.
                                </p>
                                <p class="title"><span class="fs-1-5">Disclaimers</span></p>
                                <p class="description">None of RGI's, its parent, any of its affiliates, subsidiaries,
                                    providers or their respective officers, directors, employees, agents, independent
                                    contractors or licensors (collectively the <span
                                        style="font-family: 'Neue Haas Unica Bold'">"RGI Parties"</span>) guarantees the
                                    accuracy,
                                    adequacy, timeliness, reliability, completeness, or usefulness of any of the Content
                                    and the RGI Parties disclaim liability for errors or omissions in the Content. This
                                    Website and all of the Content is provided "as is" and "as available," without any
                                    warranty, either express or implied, including the implied warranties of
                                    merchantability, fitness for a particular purpose, non-infringement or title.
                                    Additionally, there are no warranties as to the results of your use of the Content.
                                    The RGI Parties do not warrant that the Website is free of viruses or other harmful
                                    components. This does not affect those warranties which are incapable of exclusion,
                                    restriction or modification under the laws applicable to these Terms. RGI cannot
                                    guarantee and does not promise any specific results from use of the Website and/or
                                    the Service.</p>
                                <p class="title"><span
                                    class="fs-1-5">Availability</span></p>
                                <p class="description">The Website and the Service may be temporarily unavailable from
                                    time to time for maintenance or other reasons. RGI assumes no responsibility for any
                                    error, omission, interruption, deletion, defect, delay in operation or transmission,
                                    communications line failure, theft or destruction or unauthorized access to, or
                                    alteration of, user communications. RGI is not responsible for any problems or
                                    technical malfunction of any telephone network or lines, computer online systems,
                                    servers or providers, computer equipment, software, failure of email or players on
                                    account of technical problems or traffic congestion on the Internet or on the
                                    Website or combination thereof, including injury or damage to users or to any other
                                    person's computer related to or resulting from participating or downloading
                                    materials in connection with the Website and/or in connection with the Service.
                                    Under no circumstances will RGI be responsible for any loss or damage, including any
                                    loss or damage to any user Content, financial damages or lost profits, loss of
                                    business, or personal injury or death, resulting from anyone's use of the Website or
                                    the Service, any User Content or Third Party Content posted on or through the
                                    Website or the Service or transmitted to users, or any interactions between users of
                                    the Website, whether online or offline.</p>
                                <p class="title"><span
                                    class="fs-1-5">Limitation on Liability</span></p>
                                <p class="description">EXCEPT IN JURISDICTIONS WHERE SUCH PROVISIONS ARE RESTRICTED, IN
                                    NO EVENT WILL RGI OR ITS DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY
                                    THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
                                    PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE
                                    OF THE WEBSITE OR THE SERVICE OR ANY OF THE CONTENT OR OTHER MATERIALS ON OR
                                    ACCESSED THROUGH THE WEBSITE, EVEN IF RGI IS AWARE OR HAS BEEN ADVISED OF THE
                                    POSSIBILITY OF SUCH DAMAGES.<br><br>
                                    <br><br>
                                    NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, TO THE EXTENT PERMITTED
                                    BY APPLICABLE LAW RGI'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF
                                    THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY
                                    YOU TO RGI FOR THE SERVICES. IN NO CASE WILL RGI'S LIABILITY TO YOU EXCEED $1,000.
                                    YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO RGI FOR THE SERVICE, YOU SHALL BE
                                    LIMITED TO INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE PERMITTED BY LAW, AND SHALL NOT
                                    BE ENTITLED TO DAMAGES OF ANY KIND FROM RGI, REGARDLESS OF THE CAUSE OF ACTION.
                                    <br><br>
                                    CERTAIN LOCAL, STATE OR FEDERAL LAWS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                                    CERTAIN DAMAGES OR LIMITATIONS ON IMPLIED WARRANTIES. IF THESE LAWS APPLY TO YOU,
                                    SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO
                                    YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                                </p>
                                <p class="title"><span
                                    class="fs-1-5">Governing Law; Venue and Jurisdiction</span></p>
                                <p class="description">By visiting or using the Website and/or the Service, you agree
                                    that the laws of [jurisdiction], without regard to any principles of conflict of
                                    laws that would require or permit the application of the laws of any other
                                    jurisdiction, will govern these Terms. If you contract with any third party through
                                    RGI, the terms of such contract will be governed by the contractual terms prescribed
                                    by such third party.
                                </p>
                                <p class="title"><span
                                    class="fs-1-5">Indemnity</span></p>
                                <p class="description">You agree to indemnify and hold RGI, its subsidiaries, affiliates
                                    and service providers, and each of their directors, officers, agents, contractors,
                                    partners and employees, (each an “Indemnified Party”) harmless from and against any
                                    loss, liability, claim, demand, damages, costs and expenses, including solicitor
                                    fees (on a full indemnity basis), and any fines, fees or penalties imposed by any
                                    regulatory authority, arising out of or related to: (a) any dispute with another
                                    user of the Website or any third party ; (b) ’ your breach of these Terms; (c) your
                                    violation of any law, rule or regulation, or the rights of any third party; (d)
                                    RGI’s enforcement of these Terms.
                                </p>
                            </div>
                        </div>
                        <!--                        10.GENERAL PROVISIONS-->
                        <div class="content-wrapper">
                            <div class="text-wrapper">
                                <p class="title">GENERAL PROVISIONS</p>
                                <p class="title"><span class="fs-1-5">Amendments</span></p>
                                <p class="description">
                                    We may amend or modify these Terms by posting on the Website the revised Terms, and
                                    the revised Terms shall be effective at such time. If you do not agree with any such
                                    modification, your sole and exclusive remedy is to terminate your use of the
                                    Services and Website. You agree that we shall not be liable to you or any third
                                    party for any modification or termination of the Services, or suspension or
                                    termination of your access to the Services, except to the extent otherwise expressly
                                    set forth herein. If the revised Terms include a material change, we will endeavour
                                    to provide you advanced notice via the Website before the material change becomes
                                    effective.
                                </p>
                                <p class="title"><span class="fs-1-5">Dispute Resolution</span></p>
                                <p class="description">RGI shall not be liable for delays, failure in performance or
                                    interruption of service which result directly or indirectly from any cause or
                                    condition beyond our reasonable control, including but not limited to, significant
                                    market volatility, any delay or failure due to any act of God, act of civil or
                                    military authorities, act of terrorists, civil disturbance, war, strike or other
                                    labor dispute, fire, interruption in telecommunications or Internet services or
                                    network provider services, failure of equipment and/or software, other catastrophe
                                    or any other occurrence which is beyond our reasonable control and shall not affect
                                    the validity and enforceability of any remaining provisions.
                                </p>
                                <p class="title"><span class="fs-1-5">Links to Other Web Websites and Content</span></p>
                                <p class="description">The Website contains (or you may be sent through the Website or
                                    the Services) links to other websites (<span class="neue-bold">"Third Party Websites"</span>),
                                    as well as articles,
                                    photographs, text, graphics, pictures, designs, music, sound, video, information,
                                    software and other content belonging to or originating from third parties (the
                                    <span class="neue-bold">"Third Party Content"</span>). Such Third Party Websites and
                                    Third Party Content are not
                                    investigated, monitored or checked for accuracy, appropriateness, or completeness by
                                    us, and we are not responsible for any Third Party Websites accessed through the
                                    Website or any Third Party Content posted on the Website, including without
                                    limitation the content, accuracy, offensiveness, opinions, reliability or policies
                                    of or contained in the Third Party Websites or the Third Party Content. Inclusion of
                                    or linking to any Third Party Website or any Third Party Content does not imply
                                    approval or endorsement thereof by us. If you decide to leave the Website and access
                                    Third Party Websites, you do so at your own risk and you should be aware that our
                                    terms and policies no longer govern. You should review the applicable terms and
                                    policies, including privacy and data gathering practices, of any site to which you
                                    navigate from the Website.</p>
                                <p class="title"><span
                                    class="fs-1-5">Assignment</span></p>
                                <p class="description">The failure of RGI to exercise or enforce any right or provision
                                    of these Terms shall not constitute a waiver of such right or provision in that or
                                    any other instance. If any provision of these Terms is held invalid, the remainder
                                    of these Terms shall continue in full force and effect. If any provision of these
                                    Terms shall be deemed unlawful, void or for any reason unenforceable, then that
                                    provision shall be deemed severable from these Terms and shall not affect the
                                    validity and enforceability of any remaining provisions.</p>
                                <p class="title"><span
                                    class="fs-1-5">No-Waiver</span></p>
                                <p class="description">The failure of RGI to exercise or enforce any right or provision
                                    of these Terms shall not constitute a waiver of such right or provision in that or
                                    any other instance. If any provision of these Terms is held invalid, the remainder
                                    of these Terms shall continue in full force and effect. If any provision of these
                                    Terms shall be deemed unlawful, void or for any reason unenforceable, then that
                                    provision shall be deemed severable from these Terms and shall not affect the
                                    validity and enforceability of any remaining provisions.
                                </p>
                                <p class="title"><span
                                    class="fs-1-5">Governing Law; Venue and Jurisdiction</span></p>
                                <p class="description">By visiting or using the Website and/or the Service, you agree
                                    that the laws of [jurisdiction], without regard to any principles of conflict of
                                    laws that would require or permit the application of the laws of any other
                                    jurisdiction, will govern these Terms. If you contract with any third party through
                                    RGI, the terms of such contract will be governed by the contractual terms prescribed
                                    by such third party.
                                </p>
                                <p class="title"><span
                                    class="fs-1-5">Relationship of the parties</span></p>
                                <p class="description">You agree and understand that nothing in these Terms shall be
                                    deemed to constitute, create, imply, give effect to, or otherwise recognize a
                                    partnership, employment, joint venture, or formal business entity of any kind; and
                                    the rights and obligations of the parties shall be limited to those expressly set
                                    forth herein. Except for the indemnity and exculpation provisions herein, nothing
                                    expressed in, mentioned in, or implied from these Terms is intended or shall be
                                    construed to give any person other than the parties hereto any legal or equitable
                                    right, remedy, or claim under or in respect to these Terms to enforce any of its
                                    terms which might otherwise be interpreted to confer such rights to such persons,
                                    and these Terms and all representations, warranties, covenants, conditions and
                                    provisions hereof are intended to be and are for the exclusive benefit of you and
                                    us.
                                </p>
                                <p class="title"><span
                                    class="fs-1-5">Notices</span></p>
                                <p class="description">
                                    To give us notice under these Terms, the user must contact RGI by email at
                                    [*@*.com].
                                </p>
                                <p class="title"><span
                                    class="fs-1-5">Entire Agreement</span></p>
                                <p class="description">
                                    These Terms and our Privacy Policy, incorporated by reference herein, comprise the
                                    entire understanding and agreement entered into by and between you and us as to the
                                    subject matter hereof, and supersede any and all prior discussions, agreements, and
                                    understandings of any kind (including without limitation any prior versions of these
                                    Terms), as well as every nature between and among you and us. </p>
                                <p class="title"><span
                                    class="fs-1-5">Severability</span></p>
                                <p class="description">
                                    If any provision of these Terms shall be determined to be invalid or unenforceable
                                    under any rule, law, or regulation of any local, state, or federal government
                                    agency, such provision will be changed and interpreted to accomplish the objectives
                                    of the provision to the greatest extent possible under any applicable law and the
                                    validity or enforceability of any other provision of these Terms shall not be
                                    affected. If such construction is not possible, the invalid or unenforceable portion
                                    will be severed from these Terms but the rest of these Terms will remain in full
                                    force and effect. </p>
                                <p class="title"><span
                                    class="fs-1-5">Survival</span></p>
                                <p class="description">
                                    The following provisions of these Terms shall survive termination of your use or
                                    access to the Website and/or Services: Clauses 7 (Intellectual Property), Clauses
                                    9.2 to 9.7 (relating to disputes, disclaimers, limitations on liability and
                                    indemnity), Clause 10 (General Provisions), and any other provision that by its
                                    terms survives termination of your use or access to the Website and/or Services.
                                </p>
                                <p class="title"><span
                                    class="fs-1-5">English language</span></p>
                                <p class="description">
                                    Notwithstanding any other provision of these Terms, any translation of these Terms
                                    is provided for your convenience. The meanings of terms, conditions, and
                                    representations herein are subject to their definitions and interpretations in the
                                    English language. In the event of conflict or ambiguity between the English language
                                    version and translated versions of these terms, the English language version shall
                                    prevail. You acknowledge that you have read and understood the English language
                                    version of these Terms. </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TsParticles from "@/components/TsParticles";

export default {
    name: 'term-page',
    components: {
        TsParticles,
    }
}
</script>
