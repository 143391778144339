<template>
  <div class="who-we-are-block">
    <div class="info">
      <h5 class="sub-title text-left">WHO WE ARE</h5>
      <h4 class="hight-light">ROBO GLOBAL INVESTMENT</h4>
      <p class="text-block mr-5">ROBO INU FINANCE is one of the many financial projects being spearheaded by ROBO GLOBAL INVESTMENT PTE
        LTD. Beyond the individuals, ROBO GLOBAL INVESTMENT aspires to create an open ecosystem where anyone,
        including you, can gain financial freedom. ROBO INU FINANCE leverages on blockchain technology to
        enhance the lives of individuals and business operations.</p>
    </div>
    <div class="icon">
      <div class="bg d-none d-lg-block"></div>
      <img rel="preload" src="../../assets/svg/ROBOINU-Logo-Rocket1-tm.svg" alt="RoboInu Rocket" width="450"
           height="340"
           class="d-none d-lg-block">
    </div>
  </div>
</template>
<script>
export default {}
</script>
